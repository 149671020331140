.selected-checks-container {
  width: 100%;
  background-color: rgb(248, 249, 251);
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 2px;
  z-index: 1;
  padding: 0;
  margin-bottom: 30px;
  position: relative;
  border-bottom: 1px solid rgb(224, 228, 234);
  height: 48px;
  display: flex;
  align-items: center;
}

.sub-title {
  font-size: 12px;
  font-weight: 400;
  color: #6f7c87;
  padding-left: 5px;
}

.checks-dropdown {
  min-width: 200px;
  height: 32px;
  border-radius: 4px;
  background-color: white;
  margin: 0;
  font-weight: 600;
  display: -ms-inline-flexbox;
  display: inline-flex;
  outline-width: 0;
  max-width: inherit;
}

.Select-control {
  border-color: #ddd !important;
  border-radius: 3px;
  height: 30px;
  cursor: pointer;
}

.reports-heading .Select.is-focused:not(.is-open) > .Select-control,
.reports-heading .Select-control:hover {
  outline: 0;
  border-color: #ddd !important;
  box-shadow: none !important;
}

.reports-heading .Select-value-label {
  background-color: white;
  font-weight: 500;
}

.reports-heading .Select--single > .Select-control .Select-value {
  max-width: 89%;
}

.reports-heading {
  padding: 0px;
  margin: 0 auto;
  max-width: 75rem;
  width: 100%;
  height: 32px;
  display: flex;
}

.selectedURL {
  color: #2ca1db;
  margin-left: 15px;
  font-weight: 500;
  max-width: 65%;
  display: flex;
  align-items: center;
}

.selectedURL a {
  max-width: 95%;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.selectedURL img {
  margin-right: 7px;
}

.Reports-sidebar {
  list-style-type: none;
  width: 265px;
  margin-top: 0;
  position: fixed;
}

.Reports-sidebar li {
  height: 76px;
  border-radius: 5px;
  background-color: #ebedeb;
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.Reports-sidebar li a.active {
  background: white;
  border-right: 2px solid #38bf35;
  border-radius: 5px;
}

.Reports-sidebar li a.inactive {
  background: #ebedeb;
  border-right: 0;
}

.Reports-sidebar li a:visited,
.Reports-sidebar li a:hover,
.Reports-sidebar li a:active,
.Reports-sidebar li a:focus {
  background: white;
  border-right: 2px solid #38bf35;
  border-radius: 5px;
}

.Reports-sidebar li a {
  padding-left: 30px;
  font-weight: 600;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}

.reports-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 75rem;
  width: 100%;
  overflow: hidden;
}

.sub-container {
  padding: 0 10px 10px;
  min-height: 262px;
  border-radius: 2px;
  background-color: #ffffff;
}

.availability-metrics-sub-container {
  padding: 0 10px 10px;
  border-radius: 2px;
  height: 150px;
  background-color: #ffffff;
}

.availability-metrics .info-metric {
  border-right: 2px solid #ebeff3;
}

.availability-metrics-sub-container h4 {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 600;
  padding-left: 10px;
  letter-spacing: 0.2px;
}

.sub-container:first-child {
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 1px solid #fff;
}

.sub-container h4 {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.2px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 5px;
}

.sub-container .row {
  display: flex;
  justify-content: space-between;
}

.availability-metrics {
  border-top: solid 1px #ebeff3;
}

.info-box {
  height: 100px;
  border-radius: 3px;
  width: 24%;
  padding: 20px 0 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.info-title {
  color: #6f7c87;
  transition: color 0.2s ease-in-out;
  font-weight: 500;
  letter-spacing: 0.2px;
  margin: 0 14px;
  font-size: 12px;
}

.info-value {
  margin: 0 0 0 12px;
  transition: color 0.2s ease-in-out;
  font-size: 22px;
  font-weight: 700;
  align-content: flex-start;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.downtime-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.downtime-title {
  position: absolute;
  border-radius: 8px;
  background-color: #ff5a5a;
  font-size: 12px;
  font-weight: 600;
  padding: 0px 6px;
  color: #ffffff;
  left: 90px;
}

.green-circle:before {
  content: ' \25CF';
  color: #bcf2b9;
  margin-right: 5px;
}

.blue-circle:before {
  content: ' \25CF';
  color: #8abeff;
  margin-right: 5px;
}

.red-circle:before {
  content: ' \25CF';
  color: #ff3d3d;
  margin-right: 5px;
}

.chart-info {
  margin: 10px 10px 15px 10px;
}

.chart-info-left {
  float: left;
  display: inline-block;
  position: relative;
  width: 50%;
}

.chart-info-left > div {
  float: left;
  display: inline-block;
  position: relative;
  padding-right: 25px;
}

.chart-info-right {
  float: right;
  display: inline-block;
  position: relative;
  width: 50%;
}

.chart-info-label {
  font-size: 12px;
  color: #6f7c87;
}

.device-small.chart-container .chart-info-left > div {
  padding: 0;
}

.chart-info .avg,
.chart-info .max,
.chart-info .min {
  float: right;
  font-size: 12px;
  color: #6f7c87;
  padding-left: 15px;
}

.chart-info .val {
  font-weight: 600;
  color: #2d2d2d;
}

.highcharts-legend-item > span {
  top: -13px !important;
}

#container {
  height: 200px;
  margin-bottom: 10px;
}

.chart-container {
  margin-top: 10px;
  position: relative;
}

.chart-container span {
  display: inline-block;
}

.unit {
  font-size: 12px;
  color: #2d2d2d;
  padding-right: 25px;
}

table .down-circle {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #ff3d3d;
  border-radius: 15px;
}

table .down-kick {
  position: absolute;
  width: 12px;
  height: 2px;
  background-color: #fff;
  left: 10px;
  top: 32px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#downtime-history table {
  padding-left: 25px;
  width: 100%;
  border-spacing: 0 2em;
}

#downtime-history table th {
  opacity: 0.5;
  font-weight: 600;
  color: #4a4a4a;
  text-align: left;
}

#downtime-history table tr {
  font-weight: 600;
  color: #4a4a4a;
}

#downtime-history table td span {
  margin-left: 20px;
}

#downtime-history table .status-icon {
  width: 10%;
}

div[id~='container1'] {
  height: 70px;
}

div[id*='container1'] .highcharts-yaxis-labels text:last-child {
  display: none;
}

div[id*='container1'] .highcharts-series-group {
  height: 100%;
}

div[id*='container1'] .highcharts-yaxis-grid {
  width: 100%;
  stroke-linejoin: round;
}

.dashboard-graph div[id~='container1'] {
  min-width: 250px;
}

.dashboard-graph div[id~='container1'] {
  height: 50px;
}

.dashboard-graph .highcharts-background {
  fill: transparent;
}

.heat-map {
  width: 22%;
  margin: 15px 25px 0;
  position: relative;
}

.calendar-heat-maps {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  position: relative;
  min-height: 260px;
}

.device-small.calendar-container .calendar-heat-maps {
  display: inline-block;
  width: 100%;
  position: relative;
}

.device-small.calendar-container .heat-map {
  width: 100%;
  max-width: 250px;
}

.calendar-container {
  position: relative;
  margin: 5px 0;
}

.heat-map-custom-tooltip {
  border-radius: 4px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.11);
  background-color: #ffffff;
  height: 170px;
  width: 200px;
  position: absolute;
  border: solid 1px #d5d5d5;
  z-index: 1;
}

#historical-uptime h4 {
  padding-bottom: 8px;
  border-bottom: 1px solid #ebeff3;
}

#historical-uptime .__react_component_tooltip.type-dark.place-top:after {
  border-top-color: #d5d5dd;
}

.heat-map-custom-tooltip.graph-tooltip {
  height: unset;
  position: unset;
}

.graph-tooltip .heat-map-tooltip-details {
  height: auto !important;
}

.graph-tooltip .date {
  /* width: 120px !important; */
  display: inline-block;
  vertical-align: top;
  white-space: pre-wrap;
  margin-right: 40px;
}

.graph-tooltip .status-img {
  display: inline-block;
  vertical-align: top;
  margin: 0 auto;
  width: 50px;
  text-align: center;
}

.heat-map-custom-tooltip .arrow {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #8d8d8d;
  padding: 0;
  margin: 0;
  transform: rotate(0deg);
  left: 49%;
  position: relative;
  top: 1px;
}

.heat-map-custom-tooltip.no-data {
  height: 48px;
  width: 130px;
  text-align: center;
}

.heat-map-custom-tooltip.no-data .arrow {
  left: calc(50% - 6px);
}

.heat-map-custom-tooltip.no-data .date {
  font-weight: bold !important;
  font-size: 12px;
}

.heat-map-custom-tooltip .check-not-added {
  font-size: 12px;
  margin-top: 0;
  font-weight: normal;
  color: #8d8d8d;
}

.heat-map-custom-tooltip > div {
  padding: 5px;
}

.heat-map-custom-tooltip p {
  margin: 5px;
}

.heat-map-custom-tooltip .up {
  color: #383838;
  font-size: 15px;
  font-weight: bold;
}

.heat-map-custom-tooltip .date {
  color: #868686 !important;
  font-weight: normal !important;
  display: inline-block;
  width: 100%;
  word-break: break-all;
}

.__react_component_tooltip.show {
  opacity: 1;
}

.heat-map-custom-tooltip .col-6 img {
  position: absolute;
  top: 13px;
  right: 15px;
}

.heat-map-custom-tooltip .detail-title {
  color: #8d8d8d;
  font-weight: normal;
  display: block;
  background-color: #f5f5f5;
  font-size: 12px;
}

.heat-map-custom-tooltip .detail-value {
  font-weight: bold;
}

.heat-map-tooltip-details {
  background-color: #f5f5f5;
  height: 102px;
}

.calendar-heat-maps .month {
  padding-bottom: 16px;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: #6f7b87;
}

.color-empty,
.color-filled,
.color-green,
.color-red,
.color-futuredate {
  ry: 20;
  rx: 20;
}

.color-empty {
  fill: #f2f2f2;
}

.color-green {
  fill: #4cc149;
  cursor: wait;
}

.color-red {
  fill: #ff3d3d;
  cursor: wait;
}

.sub-container-heading {
  display: table;
  width: 99%;
}

.sub-container-heading span {
  vertical-align: -webkit-baseline-middle;
}

.sub-container-heading .Select-value-label {
  vertical-align: middle;
}

.sub-container-heading select {
  float: right;
  vertical-align: middle;
}

.time-dropdown {
  color: #343434;
  width: 127px;
  height: 35px;
  border-radius: 4px;
  border: solid 1px #eeeeee;
  background-color: white;
  font-weight: 600;
  margin: 0;
}

.time-filter-item {
  color: #424242;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  width: 50px;
  height: 30px;
  border: solid 1px #eeeeee;
  border-right: 0;
  background-color: #f9f9f9;
  display: inline-block;
  line-height: 30px;
  text-align: center;
  padding: 0 3px;
}

.time-filter-item:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.time-filter-item:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-right: 1px solid #eeeeee;
}

.time-filter-item.selected {
  border: 1px solid #f8ebdd;
  background: #fff;
  box-shadow: 0 0 1px #f8ebdd;
  font-weight: bold;
  color: #2f2e2e;
}

.downmark {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  vertical-align: bottom;
  position: relative;
}

.highcharts-root {
  z-index: 0;
}

.__react_component_tooltip {
  border-radius: 4px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.11);
  background-color: #ffffff;
  border: solid 0.5px #d5d5d5;
  font-size: 12px;
  font-weight: 600;
  color: rgb(88, 88, 88);
  padding: 0;
}

.react-calendar-heatmap {
  padding: 3px;
}

.color-futuredate {
  stroke-width: 1px;
  stroke: #dfdfdf;
  fill: #ffffff;
  /*pointer-events: none;*/
}

.divTable {
  display: table;
  width: 100%;
  margin: 10px auto;
}

.divTableRow {
  display: table-row;
}

.divTableHeading {
  display: table-header-group;
}

.divTableHead {
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 600;
  color: #6f7c87;
  text-transform: uppercase;
}

.outage-button {
  color: #12344d;
  font-size: 12px;
  font-weight: 600;
  text-align: right;
  cursor: pointer;
  padding: 8px 12px;
  white-space: nowrap;
  border-radius: 4;
}

.outage-button:hover {
  background: var(--color-smoke-25);
}

.divTableCell,
.divTableHead {
  border-bottom: 1px solid #ebeff3;
  display: table-cell;
}

.divTableCell {
  padding: 10px;
  vertical-align: middle;
  font-weight: 400;
}

.divTableCell span.response-code svg {
  margin-right: 8px;
}

.divTableCell span.response-code {
  justify-content: space-between;
}

.divTableFoot {
  background-color: #eee;
  display: table-footer-group;
  font-weight: bold;
}

.divTableBody {
  display: table-row-group;
}

.dayFormat {
  font-weight: 500;
  margin-bottom: 0px;
  display: inline-block;
}

.timeFormat {
  font-size: 12px;
  display: block;
}

.downtime-duration {
  width: 120px;
}

.downtime-duration img {
  float: left;
  margin-right: 10px;
  display: none;
  margin-top: -2px;
}

.incidents-notes {
  vertical-align: middle;
  resize: none;
  width: 100%;
  border-radius: 3px;
  background-color: #f4f6f8;
  border: solid 0.5px #f4f6f8;
  height: 50px;
  padding: 8px 0 5px 10px;
  box-sizing: border-box;
}

.incidents-saving-msg {
  float: right;
  padding-right: 20px;
  opacity: 0.4;
  font-size: 10px;
  margin-top: 5px;
}

.incidents-notes:focus {
  border: solid 1px #e2e2e2;
  background-color: #ffffff;
  outline: none;
}

.no-incidents {
  padding-top: 45px;
  line-height: 1.5;
  color: #6f7c87;
  text-align: center;
  position: absolute;
  width: 100%;
  display: block;
}

#availability-performance {
  display: flex;
  border-top: 1px solid #ebeff3;
  padding-bottom: 0;
}

#availability-performance .Select {
  color: #343434;
  width: 127px;
  height: 35px;
  border-radius: 4px;
  background-color: #fff;
  font-weight: 600;
  margin: 0;
  float: right;
  vertical-align: middle;
}

#availability-performance .Select .Select-value {
  line-height: 30px;
}

#availability-performance .sub-container-heading {
  justify-content: space-between;
}

.availability-section .Select-control {
  border: 0;
}

#availability-performance .availability-section .Select-value-label {
  font-weight: 500;
  line-height: 1.4;
}

#availability-performance .Select {
  width: 115px;
}

@media screen and (max-width: 1400px) {
}

.reports-wrapper {
  background: #ebeef0;
  margin-top: 10px;
  margin-bottom: 75px;
}

.react-calendar-heatmap-week rect {
  position: relative;
}

.react-calendar-heatmap-week .tooltip {
  visibility: hidden;
}

.react-calendar-heatmap-week:hover .tooltip {
  visibility: visible;
  transition: transform 0.25s ease;
}

.react-calendar-heatmap-week .tooltip text {
  font-size: 9px;
  transition: transform 0.25s ease;
}

.react-calendar-heatmap-week .tooltip {
  stroke: rgba(0, 0, 0, 0.11);
  position: absolute;
  padding: 1px;
  border-radius: 4px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.11);
  fill: #ffffff;
  transition: transform 0.25s ease;
}

.react-calendar-heatmap-week .tooltip .col-6 {
  display: block;
}

.response-infobox .ib-metric {
  border-left: 2px solid #ebeff3;
  padding: 10px 0;
}

.response-infobox .ib-metric .info-value {
  margin-top: 0;
}

.response-infobox {
  width: 200px;
  height: 100px;
  border-radius: 3px;
  /* border-left: solid 1px #dfdfdf; */
  /* background-image: linear-gradient(to bottom, #fcfcfc, #f9f9f9); */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.datepickerWrapper {
  position: relative;
  margin-top: 10px;
}

.timeRangeContainer {
  display: flex;
  align-items: center;
  position: relative;
}

.menu {
  position: absolute;
  right: 2px;
  top: 6px;
}

.disabledDownload {
  pointer-events: none;
  opacity: 0.5;
  padding-right: 40px !important;
}
