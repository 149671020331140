.visible-style {
  opacity: 1;
  height: auto;
  transition: opacity 0.5s linear;
}

.hidden-style {
  transition: opacity 0.5s linear;
  opacity: 0;
  height: 0;
  overflow: hidden;
  margin: 0;
}

.Row {
  display: table;
  border-spacing: 7px;
  background: white;
  border-radius: 5px;
  margin: 2.5px auto;
  box-sizing: border-box;
}

.application-name {
  padding-top: 10px;
  padding-left: 15px;
  color: #555555;
  opacity: 0.6;
  font-weight: 600;
  display: table;
  width: 93%;
  table-layout: fixed;
  border-spacing: 10px;
  margin: auto;
}

.check-type {
  display: inline-block;
  height: auto;
  border-radius: 5px;
  background-color: #ffffff;
  border: solid 1px #d2d2d2;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #646464;
  margin: 0 11px 0 0;
  cursor: pointer;
  padding: 9px 7px;
  font-weight: 400;
}

.Column {
  display: inherit;
}

.Column a {
  color: #5ca9d4;
  text-decoration: none;
}

.table.checks-table {
  margin-top: 0;
}

.checks-table {
  margin-bottom: 20px;
  position: relative;
}

.checkbox {
  width: 50px;
  padding: 0 15px;
}

.checks-table .row-heading span {
  position: relative;
  width: 14%;
  font-size: 12px;
  font-weight: 600;
  color: #6f7c87;
  text-align: left;
  padding-top: 1px;
  text-transform: uppercase;
}

.checks-table .row-heading span:first-child {
  width: 50px;
  padding: 0 15px;
}

.checks-table .row-heading span:last-child {
  width: 28%;
}

.device-small.checks-table .row-heading span {
  width: 20% !important;
  padding: 0 5px;
  word-break: break-all;
  text-align: center;
}

.device-small.checks-table .row-heading span:first-child {
  width: 40% !important;
  text-align: left;
}

.Column .check-name {
  font-weight: 600;
  margin-bottom: 5px;
}

.Column .check-url a {
  color: #7ba0b5;
  font-weight: normal;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  width: 200px;
}

.Column .check-url,
.Column .check-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 220px;
  width: 200px;
}

.overview {
  margin-top: 15px;
  border-spacing: 8px;
  box-shadow: 0 5px 6px -6px lightgrey;
}

.overview-heading {
  font-size: 23px;
  font-weight: bold;
  color: #4b4b4b;
  padding-bottom: 2px;
  text-align: left;
}

.col-content {
  color: #929e91;
  font-weight: bold;
  font-size: 16px;
}

.row-heading .Column {
  font-size: 12px;
  font-weight: 600;
  color: #8a8a8a;
}

.table-body .Row > div {
  width: 22%;
  font-weight: 500;
}

.table-body .Row > div:first-child {
  width: 19%;
  padding-left: 15px;
}

.table-body .Row .dashboard-graph {
  width: 48%;
}

.table-body .Row.default > div:first-child {
  width: 32%;
  padding-left: 15px;
}

.table-body .Row.default .status-col {
  width: 18% !important;
  justify-content: center;
}

.row-heading {
  height: 42px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 8px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}

.col-5 {
  width: 23%;
}

.Row .col-5 {
  max-height: 75px;
}

.delete-user .modal-body .delete-check-url {
  color: #5ca9d4;
  padding-left: 40px;
}

.col-3 {
  width: 30%;
  display: table-cell;
  text-align: center;
}

.sub-col {
  display: inline-block;
}

.uptime-summary {
  display: flex;
}

.table {
  margin-top: 15px;
}

.first-col {
  padding-left: 15px !important;
}

.first-col a {
  font-weight: 500;
}

.status-header {
  width: 100%;
  height: 85px;
  background-color: #03b66c;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  /* justify-content: center; */
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
  padding: 6px 30px;
}

.status-header img {
  margin-right: 16px;
}

.success-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  box-sizing: content-box;
  background-color: #4bc149;
  display: inline-table;
}

.checkmark {
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.table .checkmark-circle {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #4bc149;
  border-radius: 15px;
}

.table .checkmark-stem {
  position: relative;
  width: 2px;
  height: 15px;
  background-color: #fff;
  left: 17px;
  top: 7px;
}

.table .checkmark-kick {
  position: absolute;
  width: 6px;
  height: 2px;
  background-color: #fff;
  left: 11px;
  top: 20px;
}

.overview .Column {
  display: inline-table;
  margin-left: 5%;
}

.table .degraded-circle {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #fe7e37;
  border-radius: 15px;
}

.edit-check {
  margin-left: auto !important;
}

.table .degraded-stem {
  position: absolute;
  width: 2px;
  height: 10px;
  background-color: #fff;
  left: 18px;
  top: 9px;
}

.table .degraded-kick {
  position: absolute;
  width: 10px;
  height: 2px;
  background-color: #fff;
  left: 10px;
  top: 18px;
}

.table .Row {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 0;
}

.table .down-circle {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #ff3d3d;
  border-radius: 15px;
}

.table .down-kick {
  position: absolute;
  width: 12px;
  height: 2px;
  background-color: #fff;
  left: 10px;
  top: 15px;
  transform: rotate(-45deg);
}

.overview .checkmark-circle {
  position: absolute;
  width: 36px;
  height: 36px;
  background-color: #4bc149;
  border-radius: 18px;
}

.overview .checkmark-stem {
  position: absolute;
  width: 3px;
  height: 19px;
  background-color: #fff;
  left: 20px;
  top: 7px;
}

.overview .checkmark-kick {
  position: absolute;
  width: 10px;
  height: 3px;
  background-color: #fff;
  left: 13px;
  top: 23px;
}

.overview .degraded-circle {
  position: absolute;
  width: 36px;
  height: 36px;
  background-color: #fe7e37;
  border-radius: 18px;
}

.overview .degraded-stem {
  position: absolute;
  width: 3px;
  height: 14px;
  background-color: #fff;
  left: 21px;
  top: 9px;
}

.overview .degraded-kick {
  position: absolute;
  width: 14px;
  height: 3px;
  background-color: #fff;
  left: 10px;
  top: 21px;
}

.overview .down-circle {
  position: absolute;
  width: 36px;
  height: 36px;
  background-color: #ff3d3d;
  border-radius: 18px;
}

.appln-name {
  padding: 4px 10px;
  border-radius: 12.5px;
  border: solid 1px #c2d9e3;
  font-size: 12px;
  color: #555555;
  opacity: 0.9;
  font-weight: normal;
  margin: auto;
  max-width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.down-status-img,
.up-status-img,
.pause-status-img {
  margin: 0;
}

.availability-col {
  display: inline-block;
  width: 16% !important;
}

.status-col {
  width: 16% !important;
}

.sub-text {
  white-space: pre;
}

.down-image {
  content: url('../images/down_2.svg');
}

.sad-image {
  content: url('../images/sad.svg');
}

.down-label {
  font-size: 20px;
  padding-left: 13px;
}

.status-value {
  font-size: 38px;
  font-weight: 600;
}

.overview .down-kick {
  position: absolute;
  width: 19px;
  height: 2px;
  background-color: #fff;
  left: 10px;
  top: 17px;
  transform: rotate(-45deg);
}

.col-graph {
  width: 95%;
}

.remove-overflow {
  overflow: hidden;
}

.add-new-user.modal {
  position: fixed;
  z-index: 100;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
}

.add-new-user .selected-users-count {
  color: #222222;
  border-bottom: 1px solid #dadfe3;
  height: 30px;
  padding: 8px 0;
  margin-bottom: 5px;
  cursor: pointer;
}

.add-new-user .selected-users-count span.dropdown-icon {
  display: inline-block;
  background: url(../images/dropdown.svg) no-repeat center center;
  height: 14px;
  width: 14px;
  vertical-align: middle;
  cursor: pointer;
}

.add-new-user .modal-content {
  position: relative;
  width: 500px;
  background-color: #ffffff;
  float: right;
  padding: 0;
  height: 100%;
  overflow: auto;
  -webkit-animation-name: animateright;
  -webkit-animation-duration: 0.3s;
  animation-name: animateright;
  animation-duration: 0.3s;
}

@-webkit-keyframes animateright {
  from {
    right: -300px;
    opacity: 0;
  }

  to {
    right: 0;
    opacity: 1;
  }
}

@keyframes animateright {
  from {
    right: -100px;
    opacity: 0;
  }

  to {
    right: 0;
    opacity: 1;
  }
}

.close {
  color: white;
  float: right;
  font-size: 38px;
  padding: 0 10px;
  background: #b4b4b4;
  font-weight: bold;
  height: 44px;
}

.close:hover,
.close:focus {
  text-decoration: none;
  cursor: pointer;
}

.add-new-user .modal-body {
  padding: 0 15px;
  margin-bottom: 15px;
}

.errormsg {
  color: #cd0000;
  opacity: 1;
}

#add-check-modal .modal-footer {
  padding: 15px;
  bottom: 0;
  right: 0;
  width: 500px;
  background: #fff;
  float: right;
  z-index: 22;
  text-align: right;
  border-top: 1px solid #e4e2e2;
  -webkit-animation-name: animateright;
  -webkit-animation-duration: 0.3s;
  animation-name: animateright;
  animation-duration: 0.3s;
}

.integration-modal .modal-footer {
  position: relative;
  border: 0;
  float: right;
}

.integration-modal .modal-content {
  height: 100%;
}

form label {
  font-weight: 500;
  font-size: 12px;
  color: #6e6e6e;
  display: block;
  padding-top: 30px;
  padding-bottom: 5px;
}

select {
  width: 100%;
  margin: 7px 0 28px 0;
  display: inline-block;
  border: 1px solid #cbcbcb;
  box-sizing: border-box;
}

.slidecontainer {
  width: 100%;
  padding-top: 5px;
}

.slider {
  -webkit-appearance: none;
  width: 45%;
  height: 8px;
  border-radius: 5px;
  background: #dfe9f2;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  padding: 0;
  border: none;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #2097ff;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #4caf50;
  cursor: pointer;
}

.slidecontainer p {
  display: inline-block;
  text-align: right;
  float: right;
  margin: 0;
}

.slidecontainer label {
  display: inline-block;
}

.notes {
  margin: 6px 0;
  color: #a7a7a7;
}

.notes span {
  font-weight: bold;
  color: #696969;
}

button:focus,
input:focus,
select:focus {
  outline: 0;
}

button:focus,
button:hover {
  cursor: pointer;
}

@keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.lds-dual-ring {
  position: relative;
}

.lds-dual-ring div {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 60px;
  left: 50%;
  border-radius: 50%;
  border: 3px solid #000;
  border-color: #1c9b18 transparent #1c9b18 transparent;
  -webkit-animation: lds-dual-ring 1.6s linear infinite;
  animation: lds-dual-ring 1.6s linear infinite;
}

.lds-dual-ring {
  -webkit-transform: translate(-75px, -75px) scale(0.75) translate(75px, 75px);
  transform: translate(-75px, -75px) scale(0.75) translate(75px, 75px);
}

.mandatory {
  color: red;
}

.status-representation-image__up {
  height: 40px;
  width: 40px;
  background-image: url(../images/up_1.svg);
  top: 10px;
  margin-right: 10px;
  vertical-align: middle;
}

.Check-Card-Loader {
  display: table;
  position: relative;
  width: 93%;
  padding: 20px 0 70px;
  table-layout: fixed;
  border-spacing: 10px;
  border-radius: 5px;
  margin: auto;
}

.page-loader .Check-Card-Loader {
  padding: 20px 0;
}

.indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(2);
}

.indicator svg polyline {
  fill: none;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.indicator svg polyline#back {
  stroke: rgba(59, 211, 171, 0.3);
}

.indicator svg polyline#front {
  stroke: #3bd3ab;
  stroke-dasharray: 12, 36;
  stroke-dashoffset: 48;
  animation: dash 1s linear infinite;
}

.cta {
  position: fixed;
  bottom: 20px;
  right: 30px;
  color: #222;
  font-weight: bold;
}

@-moz-keyframes dash {
  62.5% {
    opacity: 0;
  }

  to {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash {
  62.5% {
    opacity: 0;
  }

  to {
    stroke-dashoffset: 0;
  }
}

@-o-keyframes dash {
  62.5% {
    opacity: 0;
  }

  to {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  62.5% {
    opacity: 0;
  }

  to {
    stroke-dashoffset: 0;
  }
}

.dashboard-graph {
  align-items: center;
  justify-content: space-evenly;
}

.dashboard-graph div[id*='container'] {
  max-height: 45px;
  position: relative;
  /* top: 5px; */
}

.dashboard-graph div[id*='container1'] {
  max-height: 20px;
}

.dashboard-chart-container {
  max-height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
}

.dashboard-graph .chart-container {
  border: none;
  margin-top: 0;
}

.dashboard-graph .highcharts-series-group {
  width: 250px !important;
  display: block;
}

.dashboard-graph .highcharts-tooltip {
  position: relative;
  top: 20px !important;
}

.table-body .row-menu {
  padding: 0 15px;
  cursor: pointer;
  height: 35px;
  position: relative;
}

.row-menu:hover {
  background-color: #f0f0f0;
  border-radius: 50%;
}

.table-body .row-menu .three-dots {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #828282;
  -webkit-box-shadow: 0px 6px 0px #828282, 0px 12px 0px #828282;
  box-shadow: 0px 6px 0px #828282, 0px 12px 0px #828282;
  display: inline-block;
}

.table-body .row-menu .dropdown-content {
  top: 0;
  right: 32px;
}

.row-menu .dropdown-content li:hover {
  background-color: #f0f0f0;
}

.hidden-advanced-settings {
  border-radius: 5px;
  background-color: rgba(179, 179, 179, 0.13);
  border: solid 1px #ebf3eb;
  height: 35px;
  font-weight: 600;
  color: #3a97ff;
  text-align: center;
  margin-bottom: 30px;
  line-height: 35px;
  cursor: pointer;
  margin-top: 5px;
}

.shown-advanced-settings {
  background-color: #ffffff;
  border: solid 1px #e4e4e4;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 30px;
}

.shown-advanced-settings h5 {
  margin-top: 10px;
  font-weight: 600;
  color: #454545;
}

.shown-advanced-settings input[type='range'] {
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 75%;
  margin-top: 15px;
}

.shown-advanced-settings input[type='range']:focus {
  outline: none;
}

.shown-advanced-settings input[type='range']::-webkit-slider-runnable-track {
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #8dcaff;
  border-radius: 8px;
  border: 1px solid #8dcaff;
}

.shown-advanced-settings input[type='range']::-webkit-slider-thumb {
  width: 16px;
  height: 16px;
  background-color: #2097ff;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.27);
  border-radius: 25px;
  background: #a1d0ff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
  background-color: #2097ff;
}

.advanced-settings__apdex {
  display: flex;
}

.advanced-settings__apdex .cosmos-tooltip {
  max-width: 220px;
  left: 33px;
  padding: 8px;
  line-height: 1.4;
}

.info-icon {
  margin-left: 5px;
  width: 13px;
  margin-bottom: 2px;
}

.advanced-settings .info-icon {
  position: absolute;
  width: 15px;
  margin-top: 2px;
}

.shown-advanced-settings .threshold-value {
  display: inline-block;
  width: 65px;
  float: right;
  text-align: right;
  color: #4f4f4f;
  position: absolute;
  right: 0;
  top: 2px;
}

.shown-advanced-settings .users {
  color: #222222;
}

.shown-advanced-settings input::placeholder,
.shown-advanced-settings :-ms-input-placeholder,
.shown-advanced-settings ::-ms-input-placeholder {
  color: #999999;
}

.shown-advanced-settings .close-icon {
  height: 15px;
  width: 18px;
  float: right;
  cursor: pointer;
}

#add-check-modal .modal-content {
  height: calc(100% - 64px);
}

#add-check-modal textarea {
  box-shadow: none;
  border: 0;
  border-bottom: 1px solid #e4e2e2 !important;
  border-radius: 0;
  resize: none;
  padding-left: 0;
}

#add-check-modal .close-icon {
  -webkit-animation-name: animateright;
  -webkit-animation-duration: 0.3s;
  animation-name: animateright;
  animation-duration: 0.3s;
  position: relative;
}

#add-check-modal .close-icon .close-image {
  padding: 4px 3px 5px 0;
  height: 21px;
  float: right;
  background-color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
  width: 22px;
}

#add-check-modal .add-check-button {
  border: none;
  border-radius: 5px;
  font-weight: 600;
  padding: 9px 20px;
  background-color: #ed7c02;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  border-radius: 3px;
  font-weight: 500;
  height: 33px;
  float: right;
}

#add-check-modal .add-check-button:disabled {
  opacity: 0.6;
}

#add-check-modal .freshworks-tooltip:before {
  left: 4%;
  top: 86%;
  width: 35%;
  z-index: 1;
}

#add-check-modal .freshworks-tooltip > * {
  opacity: 0.5;
}

.invalid-input {
  box-shadow: 0 1px 0 0 #f65050 !important;
}

.validation-msg {
  position: relative;
  bottom: 25px;
  color: #f65050;
  font-size: 12px !important;
}

.domain-url-validation-msg {
  display: inline-block;
  margin-left: 12%;
  margin-top: 5%;
}

.twilio-phone-validation-msg {
  display: inline-block;
  margin-left: 16%;
  margin-top: 7%;
}

.delete-check-modal .modal-body {
  margin: 0 0 20px 0;
  border-radius: 4px;
  background-color: #f9f9f9;
  padding: 10px;
}

.delete-check-modal .modal-content {
  padding-right: 20px !important;
}

.delete-check-modal .modal-footer {
  padding-top: 10px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.delete-check-modal .modal-header h4 {
  line-height: 1 !important;
  margin-bottom: 20px !important;
}

.checks-table .row-heading {
  border: 0;
}

.status-header__error {
  background-color: rgb(255, 61, 61);
}

.Column.circle-skeleton > span {
  margin: auto;
}

.Column.circle-skeleton .react-loading-skeleton {
  margin: auto;
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.Check-item {
  min-height: 70px;
}

.mt-small {
  margin-top: 5px;
}

.checks-table .row-heading {
  background-color: #fdfdfd;
  color: #6f7c87;
  margin-bottom: 0px;
  box-shadow: none;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 1px;
}

.Row.Check-item {
  margin-bottom: 0px;
  box-shadow: none;
  border: 1px solid #f0f0f0;
  border-radius: 1px;
  border-top: 0;
  transition: 500ms all linear;
}

span.appln-name {
  font-size: 12px;
  background-color: #f8f8f8;
  border-radius: 2px;
  border: 1px solid transparent;
  color: currentColor;
  line-height: 1.375rem;
  padding: 0 0.5rem;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #444;
  cursor: inherit;
  display: inline-block;
  font-weight: 500;
  max-width: 100%;
  overflow: hidden;
}

/* animation : alertblink 2s ease-in-out infinite */
@keyframes alertblink {
  0% {
    background: rgba(210, 44, 50, 0);
  }

  50% {
    background: rgba(210, 44, 50, 0.2);
  }

  100% {
    background: rgba(210, 44, 50, 0);
  }
}

.ping-status-error {
  animation: alertblink 2s ease-in-out infinite;
}

.table.checks-table {
  margin-left: auto;
  margin-right: auto;
  max-width: 75rem;
  width: 100%;
  /* border-top: 1px solid #e8e8e8; */
}

.status-header__container {
  display: flex;
  align-items: center;
}

#add-check-modal .multiselect-native-select {
  position: relative;
}

#add-check-modal .multiselect-native-select select {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px -1px -1px -3px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  left: 50%;
  top: 30px;
}

#add-check-modal .multiselect-native-select .btn-group {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
}

#add-check-modal
  .multiselect-native-select
  .btn-group.show
  .multiselect-container:not(.show) {
  display: block;
  top: -8px;
  position: absolute;
  max-height: 200px;
  overflow-y: auto;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  left: 0px;
  width: 470px;
}

#add-check-modal .multiselect-native-select .dropdown-toggle {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  line-height: 1.42857143;
  border-radius: 4px;
  user-select: none;
  color: #333;
  background-color: #fff;
  position: relative;
  float: left;
  border-color: #ccc;
  top: -48px;
  opacity: 0;
  overflow: hidden;
  width: 456px;
}

#add-check-modal .multiselect-native-select label {
  display: block;
  font-size: 14px;
  letter-spacing: -0.1px;
  color: #222;
  height: 100%;
  cursor: pointer;
  font-weight: 400;
  padding: 3px 20px;
}

#add-check-modal .multiselect-native-select label.checkbox {
  width: auto;
  padding: 0 15px;
}

#add-check-modal .multiselect-container input {
  transform: none;
}

#add-check-modal .modal-footer {
  padding: 15px;
  position: fixed;
  bottom: 0;
}

#add-check-modal .multiselect-native-select ul li {
  border: none;
}

#add-check-modal .multiselect-native-select ul li a {
  clear: both;
}

#add-check-modal .multiselect-native-select ul li a:hover {
  clear: both;
  text-decoration: none;
}

#add-check-modal .multiselect-native-select label.checkbox:hover {
  background: #efefef;
}

#add-check-modal .multiselect-native-select ul li .checkbox input {
  margin: 13px 10px 13px 0;
  /* top: 2px; */
  position: relative;
}

#add-check-modal .multiselect-container {
  z-index: 1000;
  display: none;
  width: 100%;
  /* padding: 5px 0; */
  margin: 2px 0 0;
  list-style: none;
  text-align: left;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
}

.editUserSettings {
  line-height: normal;
  text-align: right;
  display: block;
  position: relative;
  z-index: 5;
}

.editUserSettings span {
  color: #6e6e6e;
}

.editUserSettings a {
  color: #ed7c02;
}

#add-check-modal .multiselect-native-select > select.active ~ .btn-group {
  display: block !important;
}

#add-check-modal .tglbtn-wrapper {
  clear: both;
  display: inline-block;
  vertical-align: middle;
}

.tglbtn-wrapper .toggle-radio-btn {
  display: none;
}

.tglbtn-wrapper .toggle-radio-btn + label {
  display: inline-block;
  height: auto;
  border-radius: 5px;
  background-color: #ffffff;
  border: solid 1px #d2d2d2;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #646464;
  margin: 0 11px 0 0;
  cursor: pointer;
  padding: 9px 7px;
  font-weight: 400;
}

.tglbtn-wrapper .toggle-radio-btn:checked + label {
  border: solid 1px #ed7c02;
  font-weight: 500;
}

.input-range__label-container {
  display: none;
}

.input-range {
  height: 24px;
  position: relative;
  width: 360px;
  display: inline-block;
}

.input-range__slider {
  background: #ffffff !important;
  margin-top: -0.75rem;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px #d8d8d8;
}

.input-range__track--active {
  background: #0091ff !important;
}

.input-range__track {
  height: 4px;
  background-color: #f5f5f5;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.input-range__track:hover {
  background-color: #e1e1e1;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.custom-header-dropdown {
  display: block;
  width: 37.5%;
  top: auto;
  background: #ffffff;
  position: absolute;
  z-index: 3;
  left: 14.5%;
  height: 150px;
  overflow: auto;
  box-shadow: 0 4px 3px 2px #cccccc;
}

.custom-header-dropdown ul {
  list-style-type: none;
}

.custom-header-dropdown ul li {
  padding: 8px 10px;
  border-bottom: 1px solid #e4e2e2;
}

.alert-radio-btn {
  display: none;
}

.alert-radio-btn + label {
  padding: 0;
  border-radius: 100%;
  box-shadow: none;
  display: inline-block;
  height: 14px;
  width: 14px;
  cursor: pointer;
  position: absolute;
  border: 1px solid #d3d3d3;
  background-color: #ffffff;
}

.alert-radio-btn:checked + label {
  border-radius: 100%;
  box-shadow: none;
  display: inline-block;
  height: 14px;
  width: 14px;
  cursor: pointer;
  position: absolute;
  border: 1px solid #ed7c02 !important;
}

.alert-radio-btn:checked + label:after {
  position: relative !important;
  display: block !important;
  content: '' !important;
  width: 8px !important;
  height: 8px !important;
  top: 2px !important;
  left: 10 px !important;
  background-color: #ed7c02 !important;
  border-radius: 100% !important;
}

.edit-check-no-display {
  display: none !important;
}

.edit-check-fade-display {
  opacity: 0.5 !important;
}

.checks_delete {
  padding: 10px 20px;
}

.thead-info {
  width: 12px;
  margin: 0 0 4px 4px;
}
