.badge-controls {
  width: 420px;
  position: relative;
  background-color: #ffffff;
  overflow: auto;
  margin: 10px;
  border: 1px solid #e4e2e2;
}
.badgeWrapper {
  display: block;
  margin: 5px 0;
}
.badgeField {
  font-size: 12px;
  font-weight: 500;
  color: #6f7c87;
}
.badge-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 17px;
}
.badge-heading {
  font-weight: 600;
}
.badge-header {
  display: inline-block;
}
.badge-form-action {
  width: 420px;
  border-top: 1px solid #e4e2e2;
  padding: 5px 0;
  margin: 0 20px;
  height: 55px;
}
.badge-modal {
  padding: 0;
}
.badge-title {
  margin: 0;
  line-height: 45px;
  padding-left: 10px;
  font-weight: 500;
}
.badge-scrollable {
  overflow: auto;
  height: auto;
  max-height: calc(100% - 55px);
  padding: 0px 10px;
}
.availability-options [type='radio'] {
  position: absolute;
  left: -999px;
}
.availability-options [type='radio']:disabled + label {
  cursor: not-allowed;
  opacity: 0.5;
}
.availability-options [type='radio'] + label {
  position: relative;
  padding-left: 22px;
  cursor: pointer;
}
.availability-options [type='radio'] + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: -1px;
  width: 14px;
  height: 14px;
  border: 1px solid var(--text-link);
  border-radius: 100%;
  background: #fff;
}
.availability-options [type='radio'] + label:after {
  content: '';
  width: 8px;
  height: 8px;
  background: var(--text-link);
  position: absolute;
  top: 2px;
  left: 3px;
  border-radius: 100%;
  transition: all 0.2s ease;
}
.availability-options [type='radio']:not(:checked) + label:after {
  transform: scale(0);
}
.availability-options [type='radio']:checked + label:after {
  transform: scale(1);
}
.badge-error {
  border: 1px solid red;
}
.badge-checks-error {
  color: red;
  margin: 0;
  font-weight: normal;
  padding-top: 10px;
  font-size: 12px;
}
.badge-action {
  width: auto;
}
.badge-titlebar {
  max-width: 80%;
  margin: 0 auto;
  margin-top: 40px;
  padding: 10px;
  color: #9e9e9e;
  font-size: 14px;
  background-color: #f6f6f6;
  border-bottom: 1px solid #efefef;
  display: flex;
}
.badge-titlebar-name {
  display: inline-block;
  width: 180px;
}
.badge-titlebar-image {
  display: inline-block;
  width: 182px;
}
.badge-titlebar-code {
  display: inline-block;
  flex-grow: 1;
}
.badge-text-field {
  height: 30px;
  background-color: #ffffff;
  box-shadow: 0 1px 0 0 #dadfe3;
  border: none;
  width: 100%;
  padding: 0;
  margin-bottom: 12px;
  color: #222222;
  padding: 0 12px 0 0;
}
.badge-viewer-checks {
  border: 0;
}
.badge-viewer-checks ul {
  padding: 0;
}
.badge-checks-header {
  background-color: #f4f4f4;
  border-bottom: solid 1px #e4e2e2;
  font-weight: 600;
  color: #222222;
  padding: 10px 18px;
  margin-bottom: 10px;
}
.badge-content {
  padding: 0 18px 10px 18px;
}
.badgepage-controls {
  height: 100%;
  background: #fff;
  width: 460px;
}
.badge-fields {
  border: none;
}
.badge-count {
  color: #707070;
}
.select-checks {
  border: none;
  cursor: pointer;
  font-weight: 500;
  color: var(--text-link);
  background-color: inherit;
  padding: 0;
}
.availability-options {
  font-size: 10px;
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.availability-options .last-label {
  padding-right: 10px;
}

.availability-options:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
