@import url('toastr.css');

.root-header {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 3;
}

.top-header {
  background-color: rgb(255, 220, 184);
  text-align: center;
}

.top-header.trail {
  background-color: #faf7f2;
}

.top-header.account-suspension {
  background-color: #f45b5b;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.top-header.account-suspension button {
  background-color: #ec4949;
  color: #fff;
  border-radius: 15px;
  border-color: transparent;
  cursor: pointer;
  padding: 2px 10px;
}

.top-header.account-suspension .activation {
  margin: 0 5px;
}

.top-header .activation {
  line-height: 24px;
}

.top-header.promotion {
  line-height: 40px;
  height: 40px;
  background-color: var(--color-brand);
  color: #fff;
}

.top-header.promotion a.checkout {
  padding: 5px 10px;
  border-radius: 3px;
  background: #fff;
  margin-left: 10px;
  color: var(--color-brand);
}

.header {
  background-color: white;
  height: 60px;
  padding: 5px 24px;
  display: flex;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08);
  background: #fff;
  border-bottom: 1px #ebeff3 solid;
  padding: 0 10px;
  position: sticky;
  top: 0px;
  z-index: 1;
  vertical-align: middle;
}

.title {
  width: 22%;
  height: 35px;
  font-size: 34px;
  font-weight: 600;
  line-height: 1.03;
  letter-spacing: -0.6px;
  text-align: left;
  color: #1c9b19;
}

.header .breadcrumb {
  font-weight: 500;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  line-height: 1.7;
  -webkit-tap-highlight-color: transparent;
  padding-left: 5px;
}

.breadcrumb .icon-btn__large {
  width: 28px;
  height: 28px;
  display: inline-block;
  cursor: pointer;
  font-size: 12px;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  transition: 0.1s ease-in;
}

.breadcrumb .icon-btn__large .app-icon {
  width: 21px;
  height: 21px;
}

.breadcrumb .breadcrumb-title {
  vertical-align: middle;
  line-height: 1;
  display: inline-block;
  margin-bottom: 9px;
}

.dashboard {
  width: 22%;
  height: 19px;
  font-size: 16px;
  font-weight: 600;
}

.refresh {
  width: 33.33%;
  margin: 0 auto;
}

.refresh-msg {
  width: 175px;
  border-radius: 3px;
  background-color: #f6f6f6;
  padding: 5px;
  margin: 0 auto;
  text-align: center;
  color: #a2aab0;
  font-size: 12px;
}

.header ul {
  list-style-type: none;
  color: #4b4b4b;
  font-size: 17px;
  width: 28%;
  padding-left: 0;
}

.header li {
  float: right;
  position: relative;
}

.header li > a {
  display: block;
  text-decoration: none;
  padding: 12px 20px;
}

.header li > button {
  margin: 0 15px;
  background-color: #fcf6ef;
  color: #218fff;
  border: none;
  padding: 12px 15px;
  height: 39px;
  border-radius: 5px;
}

.save-alerts,
.btn-sm {
  border: none;
  border-radius: 5px;
  padding: 5px 20px;
}

.save-alerts,
.btn-sm {
  font-weight: 600;
  height: 33px;
  background-color: #38bf35;
  color: white;
}

.badge {
  width: 39px;
  height: 39px;
  background-color: #b1bec7;
  color: white;
  text-align: center;
  font-weight: 600;
  border-radius: 50%;
  line-height: 40px;
  font-size: 20px;
}

.badge-container .arrow {
  border-style: solid;
}

.arrow {
  box-sizing: border-box;
  height: 5px;
  width: 5px;
  border-color: #979797;
  border-width: 0px 1px 1px 0px;
  transform: rotate(45deg);
  margin: 16px 15px 15px 7px;
  cursor: pointer;
}

.badge-container {
  display: inherit;
  position: relative;
  padding-left: 20px;
  padding-bottom: 0;
  padding-top: 0;
}

.tooltip-dropdown {
  list-style: none;
  margin: 0;
  padding: 0;
  display: none;
  position: absolute;
  top: 71px;
  right: 11px;
  z-index: 1000;
  background: white;
  -moz-border-radius: 5px;
  border-radius: 4px;
  width: 118px !important;
  min-height: 70px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.05), 0 1px 4px rgba(0, 0, 0, 0.15);
  border: 0;
  border-top: 1px #ebeef0 solid;
  background-color: #ffffff;
}

.badge:hover,
.arrow-hover {
  cursor: pointer;
}

.badge-container:hover > ul {
  display: block;
}

.tooltip-dropdown li {
  float: none;
  margin: 0;
  padding: 0;
  display: block;
}

.tooltip-dropdown li a {
  height: auto;
  line-height: 1;
  display: block;
  white-space: nowrap;
  float: none;
  text-transform: none;
  font-size: 12px;
  font-weight: 600;
}

.tooltip-dropdown li:first-child {
  border-top: solid 0.5px #d5d5d5;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.tooltip-dropdown li:last-child {
  border-bottom: solid 0.5px #d5d5d5;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.tooltip-dropdown li {
  border-left: solid 0.5px #d5d5d5;
  border-right: solid 0.5px #d5d5d5;
}

.tooltip-dropdown li:hover {
  background-color: #f0f0f0;
}

.tooltip-dropdown li:first-child a {
  -moz-border-radius: 5px 5px 0 0;
  -webkit-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
}

.tooltip-dropdown li:first-child a:after {
  content: '';
  position: absolute;
  right: 30px;
  top: -8px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 8px solid #f0f0f0;
}

.badge-container:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: ' ';
  clear: both;
  height: 0;
}

button:focus,
input:focus,
select:focus {
  outline: 0;
}

button:focus,
button:hover {
  cursor: pointer;
}

.root-header.device-small #add-check {
  display: none;
}

.header ul {
  margin: 0;
}

.app-icon {
  fill: #585858;
  margin-right: -2px;
  width: 14px;
  vertical-align: middle;
  position: relative;
  top: -1px;
}

.badge-icon {
  border-radius: 3px;
  width: 32px;
  height: 32px;
  background-color: var(--color-smoke-700);
  /* box-shadow: inset 0 0 0 1px rgba(0,0,0,.04); */
  color: #fff;
  /* margin-top: 2px; */
  margin-right: 5px;
  line-height: 32px;
  font-size: 14px;
  font-weight: 500;
  user-select: none;
}

.badge-container .arrow {
  visibility: hidden;
}

.droplist-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1080;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  text-align: left;
  background-color: #fff;
  display: none;
  user-select: none;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  backface-visibility: hidden;
}

.droplist-menu.dropdown-menu--open {
  display: block;
  z-index: 1;
}

.profile-dropdown::after,
.profile-dropdown::before {
  bottom: 100%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
}

.profile-dropdown:before {
  border-color: rgba(229, 229, 229, 0);
  border-bottom-color: #ebeef0;
  border-width: 6px;
  margin-left: -6px;
  right: 7px;
}

.profile-dropdown::after {
  border-color: rgba(211, 219, 218, 0);
  border-bottom-color: #f9f9f9;
  border-width: 5px;
  margin-left: -5px;
  right: 8px;
}

.profile-dropdown {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.05), 0 1px 4px rgba(0, 0, 0, 0.15);
  border: 0;
  padding: 0;
  min-width: 180px;
  top: 35px;
  right: 5px;
  left: auto;
  border-radius: 2px;
  border-top: 1px #ebeef0 solid;
  text-align: center;
}

.text--semibold {
  font-weight: 500;
  line-height: 2;
}

.text__infotext {
  color: #6f7c87;
}

.profile-dropdown .text__infotext {
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.profile-dropdown.droplist-menu > a:hover {
  background-color: #f3f5f7;
  text-decoration: none;
}

.profile-dropdown__item {
  border-bottom: 1px #f2f2f2 solid;
  background-color: #fff;
  padding: 10px 15px;
  border-radius: 2px;
  margin: 0;
  display: block;
  line-height: 1.42857;
  white-space: nowrap;
  text-decoration: none;
  text-align: center;
}

text__content {
  margin: 0;
  padding: 0;
}

.profile-dropdown__item.profile-dropdown__textitem {
  background-color: #f9f9f9;
  border-radius: 2px 2px 0 0;
  line-height: 1.42857;
  padding: 10px 15px;
}

.userprofile-loader .react-loading-skeleton {
  height: 30px;
  margin-left: 20px;
}

.notification-btn {
  outline: 0;
  border: 0;
  height: 32px;
  display: flex;
  margin-left: 15px;
  position: relative;
  padding: 6px 9px;
}

.root-header.device-small .notification-btn {
  display: none;
}
