.settings-sidebar {
  list-style-type: none;
  width: 212px;
  position: fixed;
  margin-top: 0;
  padding-left: 0;
  background: #fff;
  height: 100vh;
  z-index: 1;
}
.settings-sidebar li {
  height: 55px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.settings-sidebar a:visited,
.settings-sidebar a:hover,
.settings-sidebar a:active,
.settings-sidebar a:focus {
  background: white;
  border-right: 2px solid #ebeef0;
}
.settings-sidebar li a {
  font-weight: 600;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: #585858;
}
.settings-sidebar li:hover {
  background: white;
}
.settings-sidebar li a.active {
  border-right: 2px solid #12344d;
  background-color: #ebeef0;
}
.settings-sidebar li a.inactive {
  border-right: 0;
}

.settings-container section.content {
  max-width: 85%;
  margin: auto;
}

.settings-container section.content.topbar {
  padding: 0 15px;
}

.integrations-heading {
  background-color: #eef5f2;
  border: solid 1px #e1e1e1;
}
.flood-protection,
.extend-false-alert-protection,
.email-report,
.users-applications-container,
.topbar,
.add-new-integrations {
  background-color: #ffffff;
}
.users-applications-container {
  min-height: 51px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.flood-protection {
  height: 83px;
}
.topbar {
  width: 100%;
  background-color: rgb(248, 249, 251);
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 2px;
  z-index: 1;
  margin-bottom: 10px;
  border-bottom: 1px solid rgb(224, 228, 234);
  height: 48px;
  display: flex;
  align-items: center;
  border-radius: 0;
}

.topbar.m_integration-type {
  box-shadow: none !important;
  background: transparent;
  min-height: 40px !important;
  padding: 0 !important;
  margin-bottom: 0 !important;
}

.topbar > .tab {
  font-weight: 600;
}

.topbar.m_integration-type > .tab {
  font-size: 16px !important;
  font-weight: 500;
}

.flood-protection .heading,
.extend-false-alert-protection .heading,
.email-report .heading,
.users-applications-container .heading {
  font-size: 15px;
  font-weight: 600;
  color: #4f4f4f;
}
.email-report .heading,
.users-applications-container .heading {
  display: inline-block;
}
.users-applications-container .popup {
  font-weight: 600;
  color: #38bf35;
  border: none;
  float: right;
  margin: 15px 0;
}
.extend-false-alert-protection .sub-heading {
  font-weight: 600;
  color: #8c8c8c;
  margin-top: 25px;
}
.left-col,
.right-col {
  display: inline-block;
}
.left-col {
  width: 80%;
}
.right-col {
  width: 18%;
  vertical-align: top;
  margin-top: 25px;
}
.flood-protection .content,
.extend-false-alert-protection .content,
.email-report .content,
.topbar .content {
  color: #8c8c8c;
}

.selectdiv {
  position: relative;
  min-width: 200px;
  width: 30%;
  margin-top: 30px;
}

.selectdiv:after {
  content: '\f078';
  font: normal normal normal 17px/1 FontAwesome;
  color: #979797;
  right: 11px;
  top: 13px;
  height: 34px;
  padding: 15px 0px 0px 8px;
  position: absolute;
  pointer-events: none;
}

..extend-false-alert-protection select::-ms-expand {
  display: none;
}
.App section {
  padding: 0 15px;
  margin-bottom: 3px;
}
.selectdiv label {
  color: #8b8b8b;
  font-size: 10px;
}
.selectdiv select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  max-width: 320px;
  height: 40px;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.75;
  color: #333;
  background-color: #ffffff;
  background-image: none;
  border: none;
  border-bottom: 1px solid #0ebeff;
  -ms-word-break: normal;
  word-break: normal;
  border-radius: 0;
  font-weight: 600;
  color: #4f4f4f;
}
.flood-protection input[type='checkbox'],
.email-report input[type='checkbox'] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.email-report label,
.flood-protection label {
  cursor: pointer;
  text-indent: -9999px;
  width: 38px;
  height: 22px;
  background: grey;
  display: inline-block;
  border-radius: 100px;
  position: relative;
  float: right;
  margin: 15px;
}

.email-report label:after,
.flood-protection label:after {
  content: '';
  position: absolute;
  top: 1.5px;
  left: 1.5px;
  width: 18px;
  height: 18px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

.email-report input:checked + label,
.flood-protection input:checked + label {
  background: #ed7c02;
}

input:checked + label:after {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.extend-false-alert-protection input[type='range'] {
  height: 25px;
  -webkit-appearance: none;
}
.extend-false-alert-protection input[type='range']:focus {
  outline: none;
}
.extend-false-alert-protection
  input[type='range']::-webkit-slider-runnable-track {
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #8dcaff;
  border-radius: 8px;
  border: 1px solid #8dcaff;
}
.extend-false-alert-protection input[type='range']::-webkit-slider-thumb {
  width: 16px;
  height: 16px;
  background-color: #2097ff;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.27);
  border-radius: 25px;
  background: #a1d0ff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
  background-color: #2097ff;
}
.range-labels {
  margin: -5px -44px 0;
  list-style: none;
}
.range-labels .active {
  color: #595959;
}
.range-labels li {
  position: relative;
  float: left;
  width: 35px;
  text-align: center;
  color: #b0b0b0;
  font-size: 12px;
  cursor: pointer;
}
.edit-application {
  width: auto;
  height: 47px;
  border-radius: 4px;
  background-color: #ffffff;
  border: solid 1px #dee1de;
  margin: 10px 0;
}
.edit-application span {
  font-weight: 600;
  line-height: 3.2;
  white-space: nowrap;
}
.edit-application img {
  width: 13px;
  height: 13px;
  float: right;
  top: 17px;
  position: relative;
  right: 8px;
}
.add-user-button {
  width: 47px;
  height: 47px;
  border-radius: 4px;
  background-color: #ffffff;
  border: solid 1px #dee1de;
  margin: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.invite-user-label {
  cursor: pointer;
}
.invite-user-image {
  position: relative;
  top: -2px;
}
.slack-popup-form {
  background: white;
  padding: 16px;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
}
.invite-user-form .primary-btn:disabled {
  opacity: 0.6;
}
.invite-user-form .validation-msg {
  bottom: 20px;
}
.invite-user-form .Select-control {
  border: none;
  box-shadow: 0 1px 0 0 #dadfe3 !important;
  outline: 0;
  border-radius: 0;
}
.invite-user-form .Select-value-label {
  color: #222222;
  font-weight: normal;
}
.invite-user-form .Select-value,
.invite-user-form .Select-input {
  padding: 0 !important;
}
.invite-user-form .modal-footer {
  margin-bottom: 0 !important;
  padding-bottom: 5px;
}
.add-user-button img {
  margin: 0 auto;
}
.settings-second-row {
  background-color: transparent;
  padding: 0 !important;
  margin: 0;
  display: flex;
}
.settings-second-row span {
  padding-left: 25px;
  padding-right: 20px;
}
.main {
  background-color: transparent;
  padding: 0 !important;
  margin: 0 0 20px;
}
.main .table {
  margin-top: 5px;
}
.main .title-row {
  opacity: 0.6;
  font-weight: 600;
}
.main .row .user-name {
  width: 30%;
}
.main .row {
  padding-left: 25px;
}
.row > span {
  display: inline-block;
}
.main .role {
  width: 20%;
}
.content-row .email,
.content-row .user-name,
.content-row .role {
  display: table-cell;
  vertical-align: middle;
}
.content-row {
  height: 50px;
  border-radius: 4px;
  background-color: #ffffff;
  border: solid 1px #dee1de;
  margin-bottom: 4px;
  display: table;
  width: 100%;
  box-sizing: border-box;
}
.content-row .user-name,
.content-row .role {
  font-weight: 600;
}
.content-row .email {
  word-break: break-word;
  width: 46%;
}
.content-row .row-menu {
  padding: 6px;
  padding-left: 20px;
  cursor: pointer;
  height: 100%;
  position: relative;
  padding-right: 12px;
}
.row-menu .dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 110px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  list-style-type: none;
  border-radius: 3px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.11);
  background-color: #ffffff;
  width: 135px;
  right: 30px;
  top: 0;
  padding: 0;
}
.dropdown-content li {
  padding: 10px 0px 10px 15px;
  font-size: 12px;
  font-weight: 400;
}
.row-menu:hover .dropdown-content {
  display: block;
}
.content-row .three-dots {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #828282;
  -webkit-box-shadow: 0px 6px 0px #828282, 0px 12px 0px #828282;
  box-shadow: 0px 6px 0px #828282, 0px 12px 0px #828282;
  position: relative;
  display: inline-block;
}
.add-new-user input:disabled {
  opacity: 0.4;
}
.add-new-user input,
.add-new-user select,
.add-new-user textarea,
input[type='tel']:not(.rrui__input-field) {
  display: inline-block;
  box-sizing: border-box;
  height: 30px;
  background-color: #ffffff;
  box-shadow: 0 1px 0 0 #dadfe3;
  border: none !important;
  border-radius: 0;
  width: 100%;
  color: #222222;
  padding: 0;
}
.timezone-picker {
  width: 100%;
}
.timezone-picker ul li > button {
  background: white;
}
.timezone-picker input {
  background: url(../images/dropdown.svg) no-repeat 99% !important;
}
.account-settings-container input {
  margin: 0 !important;
}
.account-settings {
  padding: 15px;
  max-width: 85%;
  margin: 10px auto;
  min-height: 51px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  position: relative;
  border-radius: 3px;
  background-color: #ffffff;
}
.multiselect-container input {
  box-shadow: none !important;
  background: none !important;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  border: none !important;
  transform: scale(1.25);
}
.add-new-user textarea {
  padding-bottom: 10px;
  box-shadow: rgb(218, 223, 227) 0px 0px 1px 1px;
  border-radius: 5px;
  outline: 0;
  height: auto;
}
.add-new-user select,
.add-new-user textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
}
.add-new-user input[type='range'] {
  padding: 0;
  border: none;
  border-radius: 5px;
}
.add-new-user input[type='submit'] {
  width: 100%;
  background-color: #4caf50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.add-new-user .modal-header h4 {
  font-size: 16px;
  font-weight: 600;
}
.add-new-user .modal-header h4 span {
  color: #6f7c87;
}
.add-new-user input[type='submit']:hover {
  background-color: #45a049;
}
.add-new-user .modal-header {
  padding: 15px 15px 0;
}
.add-new-integrations {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  justify-content: space-between;
  margin-top: 5px;
}
.add-new-integrations > div {
  border-radius: 4px;
  border: solid 1px #ebf3eb;
  width: 32%;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.add-new-integrations .content {
  color: #6f7c87;
  padding: 5px 25px 0 15px;
}
.add-new-integrations input[type='checkbox'] {
  float: right;
  margin: 5px;
  width: 20px;
  height: 20px;
  background-color: #51a5f4;
}
.add-new-integrations .name {
  padding-left: 15px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  padding-top: 10px;
}
.add-new-integrations .name span {
  padding-left: 5%;
}
.add-new-integrations .name img:not(.office-365) {
  max-width: 40px;
}
.add-new-integrations .checkmark {
  display: inline-block;
  width: 18px;
  height: 18px;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  float: right;
  margin: 5px;
}

.add-new-integrations .checkmark_circle {
  position: absolute;
  width: 18px;
  height: 18px;
  background-color: #51a5f4;
  border-radius: 11px;
  left: 0;
  top: 0;
}

.add-new-integrations .checkmark_stem {
  position: absolute;
  width: 2px;
  height: 11px;
  background-color: #fff;
  left: 10px;
  top: 3px;
}
.add-new-integrations .checkmark_kick {
  position: absolute;
  width: 4px;
  height: 2px;
  background-color: #fff;
  left: 6px;
  top: 12px;
}
.add-new-integration-btn {
  border-radius: 3px;
  background-color: #38bf35;
  -webkit-box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.17);
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.17);
  color: white;
  font-weight: 600;
  width: 55%;
  height: 39px;
  box-sizing: border-box;
  padding: 0;
  border: none;
}

.add-new-integrations .col-4 {
  position: relative;
}

.add-new-integrations .description {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.add-new-integrations .middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.add-new-integrations .col-4:hover .description {
  opacity: 0.2;
}

.add-new-integrations .col-4:hover .middle {
  opacity: 1;
}

.add-new-integrations .text {
  border-radius: 3px;
}
.integrations-container {
  min-width: auto !important;
  /* max-width: calc(100vw - 560px); */
}
.integration-count {
  float: right;
  margin: 5px;
  width: 32px;
  height: 19px;
  border-radius: 9.5px;
  background-color: #96bbd3;
  text-align: center;
  font-weight: 600;
  color: white;
}
.twilio-created-integrations,
.slack-created-integrations {
  margin: 0 !important;
}
.slack-created-integrations .authorize-text,
.webhook-integration .webhook-test {
  font-size: 13px !important;
  color: var(--text-link) !important;
  cursor: pointer !important;
}
.twilio-created-integrations .row,
.slack-created-integrations .row {
  padding: 15px 10px 15px 15px;
  display: block !important;
  box-sizing: border-box !important;
}
.twilio-created-integrations .row > div,
.slack-created-integrations .row > div {
  display: inline-block;
  padding: 0 !important;
}
.slack-created-integrations .row .col,
.twilio-created-integrations .row .col {
  width: 23%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.slack-created-integrations .row .slack-status,
.twilio-created-integrations .row .slack-status {
  width: 15%;
}
.slack-created-integrations .row .slack-authorize,
.twilio-created-integrations .row .twilio-recipients {
  width: 31%;
}
.align-center {
  text-align: center;
}
.twilio-created-integrations .badge-container,
.slack-created-integrations .badge-container {
  display: block;
}
.twilio-created-integrations .statuspage-action,
.slack-created-integrations .statuspage-action {
  width: 8%;
  margin-top: 0;
}
.twilio-created-integrations .more-container,
.slack-created-integrations .more-container {
  position: absolute;
}
.created-integrations-container .row > div {
  height: 100%;
  padding: 10px 0;
}
.created-integrations-container .row .name span {
  font-weight: 600;
}
.created-integrations-container {
  border-radius: 4px;
  border: solid 1px #ebf3eb;
  padding: 0 !important;
  margin-top: 8px;
  margin-bottom: 16px !important;
  background-color: #ffffff;
}
.created-integrations-container .row {
  display: table;
  width: 100%;
  border-bottom: solid 0.5px #dddddd;
}
.created-integrations-container .col {
  display: table-cell;
  vertical-align: middle;
  word-break: break-word;
}
.created-integrations-container .row .logo {
  width: 23%;
}
.created-integrations-container .row .status {
  width: 16%;
}
.created-integrations-container .row .logo img,
.created-integrations-container .row .logo span {
  vertical-align: middle;
  padding-left: 15px;
}
.created-integrations-container .row .logo img {
  width: 35px;
}
.created-integrations-container .row .status span {
  vertical-align: middle;
  height: 22px;
  border-radius: 11px;
  width: 72px;
  display: table-cell;
  text-align: center;
  font-weight: 600;
  color: white;
}
.created-integrations-container .row .status .active-state {
  background-color: #38bf35;
}
.created-integrations-container .row .status .deactive-state {
  background-color: #ff7e7e;
}
.created-integrations-container .row .url {
  width: 45%;
  padding-right: 10px;
}
.created-integrations-container .row .check {
  margin-top: 8px;
  width: 25%;
  padding-left: 10%;
}
.created-integrations-container .row .name {
  width: 19%;
}
.created-integrations-container .row .edit {
  width: 7%;
  font-weight: 600;
  color: #199e4f;
  cursor: pointer;
}
.created-integrations-container .row .test {
  font-weight: 600;
  color: #25a9ff;
  cursor: pointer;
  width: 5%;
}
.popup {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.popup .popuptext {
  min-width: 300px;
  color: black;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  top: 172%;
  left: 0%;
  margin-left: -80px;
  border-radius: 4px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: solid 1px #e6e6e6;
}
#addNewAppPopup form,
[id^='editPopup'] form {
  padding: 10px 30px 0;
  text-align: left;
}
#addNewAppPopup input,
[id^='editPopup'] input {
  margin: 0px 0 30px 0;
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 35px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 1px 0 0 #dadfe3;
  box-shadow: 0 1px 0 0 #dadfe3;
  border: none;
  border-radius: 0;
  width: 100%;
  color: #222222;
  padding: 0;
}
[id^='editPopup'] {
  top: 115% !important;
  left: 50% !important;
}
[id^='editPopup']::after {
  bottom: 100%;
  left: 12%;
  margin-left: -7px;
  border-width: 7px;
}
#addNewAppPopup .modal-footer,
[id^='editPopup'] .modal-footer {
  float: none;
  padding-top: 10px;
}
#addNewAppPopup .primary-btn,
[id^='editPopup'] .primary-btn {
  margin: 0 15px 0 0;
}
.popup .popuptext::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #e6e6e6 transparent transparent transparent;
  transform: rotate(180deg);
}

/* Toggle this class - hide and show the popup */
.popup .show {
  visibility: visible;
  -webkit-animation: fadeIn 0.25s;
  animation: fadeIn 0.25s;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.save-alerts {
  margin-top: 20px;
}
#addNewAppPopup .close-icon {
  float: right;
  position: relative;
}
.popuptext .close-icon {
  width: 20px;
  height: 12px;
  top: -8px;
  right: 0px;
  padding: 10px;
}

.delete-user.modal {
  position: fixed;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

/* Modal Content */
.delete-user .modal-content {
  position: relative;
  margin: auto;
  padding: 0;
  width: 35%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  border-radius: 5px;
  background-color: #ffffff;
  border: solid 1px #ebf3eb;
  padding-left: 20px;
}
.delete-user .modal-header {
  font-weight: bold;
}
.delete-user .modal-header h4 {
  margin-bottom: 30px;
  line-height: 2;
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

.delete-user .modal-footer {
  border: 0 !important;
  text-align: left !important;
  padding-top: 30px;
  padding-bottom: 10px;
}
.delete-user .modal-footer p,
.delete-user .modal-footer button {
  display: inline-block;
}
.delete-user .modal-footer p {
  vertical-align: middle;
  height: inherit;
  margin: 0;
  font-weight: 600;
}
.delete-user .modal-footer .btn-danger {
  width: 101px;
  height: 35px;
  border-radius: 5px;
  margin-left: 15px;
  font-weight: 600;
  color: #ffffff;
  border: none;
}
.delete-user .modal-footer .btn-danger {
  background-color: #fd7070;
  color: white;
}

.delete-user .modal-body input {
  margin-bottom: 10px;
  margin-left: 50px;
  width: 46%;
}
.delete-user .modal-body p span {
  padding-left: 30px;
  font-weight: 600;
}
.delete-user .modal-body .delete-user-email {
  color: #5ca9d4;
}
.delete-user .modal-body img {
  margin-left: 15px;
  width: 17px;
  height: 31px;
  cursor: pointer;
}
.delete-user .close-image {
  float: right;
  padding-right: 10px;
  background-color: white;
  cursor: pointer;
  width: 23px;
  height: 13px;
  padding-top: 10px;
}
.users-groups-container .users-header > span:hover,
.users-groups-container .users-header > .active-tab {
  border: none;
}
.users-groups-container .popup {
  font-weight: 400;
  border: none;
  color: var(--base-color);
}
.user-info .profile-pic {
  display: inline-block;
  height: 28px;
  width: 28px;
  line-height: 28px;
}
.users-container .row {
  height: 60px;
  background-color: #ffffff;
  border: 1px solid #ebf3eb;
  display: flex;
  align-items: center;
  margin-bottom: 2.5px;
  padding: 0 10px;
  transition: 500ms all linear;
}
.user-type {
  width: 11%;
}
.user-type .user-type-dropdown {
  margin: 0 !important;
}
.user-type-dropdown {
  height: 30px;
  color: #717171;
  border-radius: 4px;
}
.user-type .Select-input {
  height: 25px;
}
.expand-applications {
  position: relative;
  padding: 20px 0;
  margin: 0 auto;
}

.expand-applications > .tooltip-dropdown {
  width: auto !important;
  top: 49px;
  right: -75px;
  max-height: 180px;
  overflow-y: auto;
}
.expand-applications:hover > .tooltip-dropdown {
  display: block;
  border-radius: 2px;
}
.users-groups-container .user-apps .add-user-icon {
  top: 10px;
  position: relative;
  line-height: 0;
}
.users-groups-container .user-apps .add-app-to-user .tooltiptext {
  margin: 0;
  right: -27px;
  top: 90%;
}
.expand-applications > .tooltip-dropdown:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border: 7px solid transparent;
  border-bottom: 7px solid #f4f6f8;
  top: -15px;
  left: 50%;
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.expand-applications > .tooltip-dropdown span {
  display: block;
  margin: 10px !important;
}
.user-type .Select-placeholder,
.user-type .Select--single > .Select-control .Select-value {
  line-height: 30px;
}
.user-type .Select-control {
  width: 100%;
  height: 25px !important;
  border-radius: 3px;
  border: solid 1px #d9dfe4;
  box-sizing: border-box;
}
.Select-control .Select-arrow {
  border: none;
}
.user-type .Select-value-label {
  color: #717171 !important;
}
.Select-control .Select-arrow-zone {
  background: url(../images/dropdown.svg) no-repeat center center;
}
.users-container .delete-user-icon {
  width: 3%;
  float: right;
  text-align: right;
}
.users-container .delete-user-icon img {
  height: 100%;
  vertical-align: middle;
}
.users-container .row .user-info {
  width: 29%;
  display: inline-flex;
  align-items: center;
}
.users-groups-container .header span {
  display: inline-block;
  line-height: 32px;
  font-size: 12px;
  font-weight: 600;
  color: #6f7c87;
  text-transform: uppercase;
}
.users-groups-container .header .name {
  width: 18%;
}
.users-groups-container .header .email {
  width: 22%;
}
.users-groups-container .header .type {
  width: 14%;
}

.users-groups-container .header .report {
  width: 12%;
}

.users-groups-container .header .checks {
  width: 15%;
}

.user-management .user-type-dropdown {
  height: 25px !important;
}
.user-management .row:hover {
  background: #fafafa;
}
.user-management .row .user-info {
  width: 36%;
}

.user-management .row .weekly-report {
  width: 13%;
  text-align: center;
}

.user-management .row .subscriber-type {
  border-radius: 3px;
  text-align: center;
  line-height: 25px;
  box-sizing: border-box;
}
.users-container .row .user-info .user-name {
  font-size: 15px;
  font-weight: 600;
  display: block;
  margin-bottom: 5px;
}
.users-container .row .user-name-email {
  margin-left: 10px;
}
.users-groups-container .create-new-group-modal-checkbox {
  position: relative;
  top: 1px;
}
.popup form {
  line-height: normal;
}
.users-container .row .user-info .user-email {
  color: #717171;
  margin-top: 6px;
}
.users-container .row .user-apps {
  width: 56%;
  display: flex;
  align-items: center;
}

.__react_component_tooltip {
  font-weight: 400;
  padding: 5px 20px !important;
}

.user-management .row .user-apps {
  width: 19%;
}
.user-management .full-name {
  width: 40%;
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.user-management .user-mini-email {
  margin-left: 10px;
  width: 200px;
  max-width: 220px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.user-management .row .tooltip-dropdown > p {
  color: #717171;
  padding: 0 10px;
  width: 200px;
  max-width: 220px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.users-container .row .user-apps .close-icon-container .close-icon,
.expand-applications .close-icon-container .close-icon {
  height: 6px;
  width: 12px;
  position: relative;
  right: 1px;
  bottom: 1px;
}
.users-container .row .user-apps .close-icon-container,
.expand-applications .close-icon-container {
  position: relative;
  left: 8px;
  display: inline-block;
  background-color: #d8dde3;
  border-radius: 50%;
}
.users-container .row .user-name-email {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
}

.users-container .row .user-name-email:hover {
  cursor: default;
}
.users-groups-container .header {
  height: 32px;
  background-color: #f6f6f6;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
  display: block;
  margin-top: 12px;
  border: none;
}
.users-groups-container .create-group-popup form {
  padding: 10px 20px 0;
  text-align: left;
}
.users-groups-container .create-group-popup::after {
  left: 80% !important;
}
.users-groups-container .create-group-popup {
  top: 75%;
  z-index: 9;
  left: -100%;
  margin-left: -123px;
}

.users-groups-container .create-group-popup .errormsg {
  position: absolute;
  top: 68px;
  color: red;
  left: 20px;
  font-size: 12px;
}
.users-groups-container {
  min-width: 790px;
  margin-bottom: 75px;
}
@media screen and (min-width: 0) and (max-width: 790px) {
  .users-container {
    overflow-x: auto;
  }
}
.users-groups-container .create-group-popup input {
  margin: 0px 0 25px 0 !important;
  -webkit-box-sizing: border-box;
  -webkit-box-shadow: 0 1px 0 0 #dadfe3;
  border: none;
  width: 100%;
  height: 30px;
  outline: none;
  color: #222222;
}
.create-integration-popup {
  left: -116% !important;
  top: 85% !important;
  margin-left: 0 !important;
}
.rrui__select__selected-content {
  max-height: 60px;
  max-width: 60px;
  min-width: 30px;
  min-height: 30px;
}
.rrui__select__selected-content img {
  max-height: 30px;
  max-width: 30px;
  position: relative;
  top: 3px;
}
.rrui__select__selected-content .rrui__select__arrow {
  margin: 0;
}
.rrui__select--compact {
  width: 13% !important;
}
.rrui__select__button {
  border: none !important;
}
.react-phone-number-input {
  width: 94%;
  margin: 10px 0 25px;
  display: inline-block;
}
.react-phone-number-input__row {
  display: flex;
  justify-content: space-between;
}
.rrui__select__selected-content .react-phone-number-input__icon {
  max-height: 30px;
  max-width: 30px;
}
.rrui__select__selected-content .rrui__select__selected-label {
  flex: none;
  margin-right: 10px;
  min-width: 30px;
  min-height: 30px;
}
.react-phone-number-input__row input {
  width: 84%;
  margin-bottom: 0;
}
.create-integration-popup .Select {
  top: -20px;
  border: none;
  outline: none;
  box-shadow: none !important;
}
.create-integration-popup .Select-menu-outer {
  margin-bottom: 2px;
}
.users-groups-container .create-group-popup .modal-footer {
  float: none;
  padding-top: 10px;
  margin-bottom: 17px;
}
.users-groups-container .create-group-popup .primary-btn {
  margin: 0 15px 0 0;
}
.users-groups-container .create-group-popup .close-icon {
  width: 20px;
  height: 12px;
  top: 0;
  right: 0px;
  padding: 0;
}
.users-groups-container .create-group-popup .close-icon-span {
  position: relative;
  float: right;
  top: 0px;
  right: 10px;
  margin: 0;
}
.users-groups-container .invite-user-form .user-type-dropdown {
  margin: 5px 0 30px;
}
.users-groups-container .create-group-popup input[type='checkbox'] {
  box-shadow: none;
  display: inline-block;
  height: 100%;
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
.users-groups-container .create-group-popup input[type='checkbox'] + label {
  font-weight: 500;
  display: inline-block;
  color: #222222;
}

.users-groups-container .create-group-popup form p {
  margin: 0;
}
.users-groups-container .create-group-popup form .filled-in {
  position: absolute;
  left: -9999px;
  visibility: hidden;
}
.users-groups-container .create-group-popup form .filled-in:checked + label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.users-groups-container
  .create-group-popup
  form
  .filled-in:checked
  + label::after {
  top: 0px;
  width: 20px;
  height: 20px;
  border: 2px solid #26a69a;
  background-color: #26a69a;
  z-index: 0;
  content: '';
  border: 1px solid #f30;
}
.users-groups-container
  .create-group-popup
  form
  .filled-in:checked
  + label::before {
  content: '';
  border: 1px solid #f30;
  top: 0;
  left: 1px;
  width: 8px;
  height: 13px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  -webkit-transform: rotateZ(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
.users-groups-container .create-group-popup input:focus ~ .floating-label,
.users-groups-container
  .create-group-popup
  input:not(:focus):valid
  ~ .floating-label {
  top: 20px;
  opacity: 0.5;
  font-size: 12px;
  color: #222222;
}
.floating-label {
  font-weight: 500;
  position: absolute;
  pointer-events: none;
  top: 48px;
  transition: 0.2s ease all;
}
input[type='checkbox']:checked {
  border-color: rgb(0, 178, 0);
  box-shadow: 0 0 3px #006a00;
  background-color: rgb(0, 178, 0);
}

.users-groups-container .tooltip .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 5;
  top: 90%;
  right: -10%;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  font-weight: lighter;
  line-height: initial;
  white-space: nowrap;
  padding: 5px 10px;
}

.users-groups-container .tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  border-width: 5px;
  border-style: solid;
  opacity: 0.5;
  border-color: transparent transparent black transparent;
}
.users-groups-container .delete-user-icon .tooltiptext {
  right: -170%;
  width: 85px;
}
.users-groups-container .delete-user-icon .tooltiptext::after {
  left: 50%;
}
.users-groups-container .subscriber-container .delete-user-icon .tooltiptext {
  right: -20px !important;
}
.users-groups-container .subscriber-container .tooltip .tooltiptext::after {
  left: 64%;
}
.users-groups-container .tooltip:hover .tooltiptext {
  visibility: visible;
}
.multiselect-native-select select,
.users-container .multiselect-native-select .dropdown-toggle {
  display: none;
}
.freshdesk-integration-modal .multiselect-native-select .dropdown-toggle {
  margin: 0;
  display: inline-block;
  box-sizing: border-box;
  height: 30px;
  background-color: #ffffff;
  box-shadow: 0 1px 0 0 #dadfe3;
  border: none;
  border-radius: 0;
  width: 100%;
  color: #222222;
  padding: 0;
  text-align: left;
  margin-top: 3px;
}
.freshdesk-integration-modal .multiselect-native-select .multiselect-container {
  margin: 0;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: solid 1px #e6e6e6;
  padding: 0 15px;
  display: none;
}
.freshdesk-integration-modal .multiselect .Select-arrow-zone {
  cursor: pointer;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 20px;
  float: right;
  background: url(../images/dropdown.svg) no-repeat center center;
  border: none;
}
.freshdesk-integration-modal .multiselect .multiselect-selected-text {
  display: inline-block;
  width: 90%;
}
.freshdesk-integration-modal .multiselect .Select-arrow {
  display: inline-block;
  height: 0;
  width: 0;
  position: relative;
  border: 0;
}
.freshdesk-integration-modal .modal-footer {
  margin-top: 30px;
}
.multiselect-native-select ul {
  list-style-type: none;
  padding: 0;
  border-top: solid 1px rgba(205, 205, 205, 0.4);
}
.multiselect-native-select ul:empty {
  display: none;
}
.created-integrations-container:empty {
  border: none;
}
.multiselect-native-select ul li {
  border-bottom: solid 1px rgba(205, 205, 205, 0.4);
}
.multiselect-native-select label {
  font-size: 15px;
  font-weight: normal;
  letter-spacing: normal;
  opacity: 1;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}
.multiselect-native-select ul li .checkbox input {
  margin: 13px 0;
}
.multiselect-native-select a:focus {
  outline: 0 !important;
  border: 0 !important;
}
.add-app-to-user .invite-user-form > label {
  font-weight: normal;
  color: #4a4a4a;
}
.add-app-to-user .modal-body {
  margin-left: -200px;
  top: 94%;
}
.add-app-to-user .modal-body::after {
  border-width: 6px;
  left: 79% !important;
}
.subscriber-container .row .user-info {
  width: 37%;
}
.subscriber-container .row .user-apps {
  width: 60%;
}
.subscriber-container .row .user-name-email {
  max-width: 230px;
}
.subscriber-container .row .user-apps > span {
  margin-right: 10px;
}
.settings-groups {
  height: 64px !important;
  justify-content: space-between;
  padding: 0 25px !important;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.12);
}
.settings-groups span {
  font-size: 15px;
  font-weight: 600;
}
.settings-groups .app-name {
  display: flex;
  align-items: center;
  font-size: 15px;
}
.settings-groups .app-name .edit-icon {
  cursor: pointer;
  padding: 5px 15px 5px 5px;
}
.settings-groups .delete-group-container {
  height: 26px;
  border-radius: 5px;
  background-color: #efefef;
  padding: 0 10px;
  display: flex;
  align-items: center;
  font-weight: normal;
  color: #ff5b5b;
  cursor: pointer;
  margin-left: auto;
}
.settings-groups .collapse-container {
  width: 20px;
  height: 100%;
  margin-left: 20px;
  display: table-cell;
}
.settings-groups .collapse-container .collapse-icon {
  font-size: 10px;
  width: 15px;
  vertical-align: middle;
  height: 100%;
}
.settings-groups .close-detail {
  transform: rotate(-90deg);
}
.settings-groups .delete-group-container img {
  margin-right: 10px;
}
.settings-groups .card {
  border-radius: 11px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.07);
  background-color: #f4f6f8;
  border: solid 1px #dfe4ea;
  padding: 0 10px;
  align-items: unset !important;
  width: 46.5% !important;
  margin-bottom: 15px;
  position: relative;
}
.settings-groups .card .user-name-email {
  margin-left: 13px;
  white-space: unset;
  text-overflow: unset;
  max-width: none;
}
.settings-groups .card .user-name {
  margin-bottom: 5px;
}
.settings-groups .card .profile-pic {
  background-color: #aabeca;
}
.settings-groups .card .user-email {
  font-weight: normal;
  color: #717171;
  display: block;
  margin-bottom: 2px;
}
.settings-groups .card .role {
  font-weight: 600;
  color: #717171;
}
.settings-groups .card .fa-times-thin {
  font-size: 15px;
  color: #979797;
  padding: 5px;
  background-color: #d1d7dd;
  border-radius: 50%;
  padding-top: 0px;
  padding-bottom: 3px;
}
.settings-groups .card .fa-times-thin-para {
  position: absolute;
  right: 13px;
  cursor: pointer;
}
#create-twilio-integration .fa-times-thin-para {
  position: absolute;
  cursor: pointer;
  display: inline-block;
  position: relative;
  bottom: 5px;
}
#create-twilio-integration .fa-times-thin {
  font-size: 12px;
  padding: 4px;
  padding-top: 0px;
  padding-bottom: 2px;
  padding-left: 4px;
  pointer-events: none;
}
.settings-groups-delete-group .modal-footer {
  text-align: right !important;
  margin-right: 25px;
  padding-top: 20px;
}
.users-groups-container .app-name .create-group-popup {
  top: 90%;
  z-index: 9;
  left: -14%;
  margin-left: 0;
}
.users-groups-container .app-name .create-group-popup::after {
  left: 7% !important;
}
.users-groups-container .app-name .edit-icon .tooltiptext {
  top: 65%;
  right: 49%;
}

.freshdesk-integration,
.freshservice-integration,
.webhook-integration {
  border-radius: 5px;
  border: solid 1px #ebf3eb;
}
.freshdesk-integration {
  background-image: linear-gradient(to top, #ffffff, #f2fff9);
}
.freshservice-integration {
  background-image: linear-gradient(to bottom, #f4fdff, #ffffff);
}
.webhook-integration {
  background-color: #ffffff;
}
.freshdesk-integration-modal .form-fields .Select {
  margin-top: 5px;
}
.freshdesk-integration-modal .form-fields .Select-control,
.freshdesk-integration-modal .form-fields .Select-input {
  height: 25px;
}
.freshdesk-integration-modal .form-fields .Select-value {
  line-height: 30px;
}
.freshdesk-integration-modal .form-fields .Select-value-label {
  font-size: 15px;
  color: #222222;
}
.freshdesk-integration-modal .sign-up-freshdesk {
  font-size: 12px;
  line-height: normal;
  color: #6f7c87;
  text-align: right;
  margin: 0;
}
.freshdesk-integration-modal .sign-up-freshdesk .sign-up-link {
  font-weight: 600;
  color: #379cff;
  cursor: pointer;
}
.integration-error-span {
  color: red;
  font-weight: 500;
  font-size: 12px;
  display: block;
}
.integration-item {
  margin-bottom: 6px !important;
  padding-top: 8px !important;
}
.integration-item .name {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  position: relative;
}
.integration-item .name span {
  padding-left: 2%;
}
.integration-item .name img:not(.office-365) {
  max-width: 40px;
}

.integration-item .integration-features {
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  width: 100%;
  text-align: center;
  flex-direction: row;
  margin-bottom: 24px;
}

.integration-item .integration-features .feature-item {
  display: block;
  border-right: 1px solid #e1e1e1;
  padding-right: 24px;
}

@media only screen and (max-width: 1280px) {
  .integration-item .integration-features .feature-item {
    padding: 0 6px;
  }
}

.integration-item .integration-features .feature-item:last-child {
  border: none;
}

.integration-item .integration-features span img {
  width: 25px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
}

.integration-item .integration-features .feature-text {
  display: inline-block;
  vertical-align: middle;
}

.integration-item .name .add-new-integration-button {
  border-radius: 3px;
  border: solid 1px rgba(33, 143, 255, 0.3);
  font-weight: 600;
  color: #686868;
  background-color: transparent;
  margin-left: auto;
  height: 35px;
  padding-right: 10px;
  padding-left: 10px;
}
.integration-item .name .integrated-blue-tick {
  position: absolute;
  right: 95px;
}
.integration-item .name .add-new-integration-button:hover {
  color: white;
  background-color: var(--bg-dark);
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.17);
}

.integration-item .moreIntegration {
  border-radius: 5px;
  border: solid 1px var(--border-color);
  padding: 9px 0px 9px 12px;
  background: url(../images/web-link.svg) 14px 10px no-repeat;
  text-align: center;
  color: var(--text-link);
  display: block;
  width: 165px;
  margin: 0 auto;
  background-size: 13px;
  margin-bottom: 1rem;
  font-size: 13px;
}

.integration-item .moreIntegration:hover {
  box-shadow: 0 2px 4px var(--color-smoke-50);
}

.integrations-list {
  margin-top: 10px;
}

.integrations-list section.fixedHeader {
  position: fixed;
  z-index: 1;
  width: calc(100% - 277px);
  top: 50px;
}

.webhook-integration-modal .user-type-dropdown .Select-input input {
  width: 0 !important;
}
.add-new-user .edit-integration-close-container {
  -webkit-animation-name: animateright;
  -webkit-animation-duration: 0.3s;
  animation-name: animateright;
  animation-duration: 0.3s;
  position: relative;
}
.add-new-user .edit-integration-close-container svg {
  width: 13px;
  height: 13px;
  fill: white;
  line-height: 0.4;
  margin-left: 6px;
  margin-top: 6px;
}

.add-new-user .edit-integration-close-container .edit-integration-close-image {
  height: 24px;
  float: right;
  background-color: black;
  cursor: pointer;
  width: 24px;
}

.zapier-modal .created-integrations-container {
  border: 0;
  margin: 0 !important;
}

.delete-user-from-org-modal {
  width: 30% !important;
}
.delete-user .delete-user-from-org-modal-footer {
  text-align: right !important;
  margin-right: 25px;
  padding-top: 20px !important;
}
.dynamic-values {
  font-size: 15px;
  font-weight: 600;
}
.no-more-groups-to-add {
  display: inline-block;
  width: 100%;
  margin: 10px 0;
  border-top: 1px solid hsla(0, 0%, 80%, 0.4);
  border-bottom: 1px solid hsla(0, 0%, 80%, 0.4);
  padding: 15px;
  color: hsla(0, 0%, 80%, 1);
  color: #4a4a4a;
  padding-left: 0;
}

.created-integrations-container .row-menu {
  margin: 0px 8px !important;
  cursor: pointer;
  height: 30px !important;
  position: relative;
  text-align: center;
}
.created-integrations-container .row-menu .three-dots {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #828282;
  box-shadow: 0px 12px 0px #828282, 0px 6px 0px #828282;
  display: inline-block;
}
.created-integrations-container .row-menu .dropdown-content {
  bottom: -71px;
  margin: 0;
  min-width: 90px;
}
.created-integrations-container .row-menu .two-options {
  bottom: -37px;
  right: 22px;
}
.created-integrations-container .row-menu .dropdown-content li {
  padding: 10px 0px 10px 15px;
  font-size: 12px;
  font-weight: 600;
  box-sizing: border-box;
  text-align: left;
}
.row-menu .dropdown-content li:first-child {
  border-top: solid 0.5px #d5d5d5;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.row-menu .dropdown-content li:last-child {
  border-bottom: solid 0.5px #d5d5d5;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.row-menu .dropdown-content li {
  border-left: solid 0.5px #d5d5d5;
  border-right: solid 0.5px #d5d5d5;
}

.id-field {
  display: flex;
  margin-top: 10px;
}

.id-field .id-field--domain,
.id-field .id-field--protocol {
  font-weight: 500;
  padding-right: 5px;
  letter-spacing: 0.2px;
  vertical-align: bottom;
  line-height: 32px;
  color: #6f7c87;
}

.id-field .id-field--input {
  width: 180px;
  height: 32px;
  margin-right: 5px;
  margin-bottom: 10px;
  flex-grow: 1;
  padding: 0 7px;
}

.anc-group {
  display: flex;
  margin-top: 10px;
  height: 40px;
  align-items: center;
}

.anc-group .anc-group--group .validation-msg {
  bottom: 10px;
}
.anc-group .anc-group--group input {
  margin: 0 0 10px;
}

.anc-group .anc-group--label {
  font-weight: 400;
  padding-right: 20px;
  letter-spacing: 0.2px;
  line-height: 32px;
  color: #6f7c87;
}
.anc-group .anc-group--group .user-group-dropdown {
  width: 200px;
  margin: auto 0;
}

#invite-user-to-organization-form .role {
  padding: 0;
  margin-top: 10px;
  border: none;
}
#invite-user-to-organization-form .role label {
  border: dashed 1px #d3d3d3;
  border-bottom: 0;
  padding: 15px 8px;
  color: #222222;
  opacity: 1;
  font-weight: normal;
}

#invite-user-to-organization-form .role label:disabled {
  opacity: 0.6;
}

#invite-user-to-organization-form .role label:last-child {
  border-bottom: dashed 1px #d3d3d3;
  padding: 15px 8px;
  color: #222222;
  opacity: 1;
  font-weight: normal;
}
#invite-user-to-organization-form .role input[type='radio'] {
  box-shadow: none;
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 0px 10px 5px 0 !important;
}
#invite-user-to-organization-form .role label span {
  display: block;
  color: #878787;
  font-size: 12px;
  margin-left: 25px;
}
#invite-user-modal {
  min-width: 420px;
  top: 24px;
  right: 0;
  left: inherit;
}
.slack-integration button.slack-disabled {
  opacity: 50%;
}
.slack-integration {
  border-radius: 5px;
  background-color: #ffffff;
  border: solid 1px #ebf3eb;
}
.slack-integration .slack-title img {
  margin-right: 10px;
}

.slack-integration .slack-title {
  display: flex;
  width: 200px;
  height: 40px;
  align-items: center;
}

.slack-integration .name {
  display: flex;
  padding: 15px 0;
  justify-content: space-between;
}
.add-mobile-number {
  background-color: #f4f4f4;
  text-align: center;
  padding: 6px 0;
  color: #222222;
  cursor: pointer;
  margin-bottom: 20px;
}
.account-settings-container .col-6 {
  width: 45%;
  display: inline-block;
  padding-top: 10px;
}
.account-settings-container .col-6 .timezone-picker input {
  padding-left: 0 !important;
}
.edit-account-button:disabled {
  opacity: 0.6;
}
.account-settings-container .col-6 label {
  font-size: 12px;
  color: #6f7c87;
}
.account-settings-container .current-plan {
  width: 40%;
  display: inline-block;
}
.account-settings-container .current-plan {
  width: 40%;
  display: inline-block;
}
.account-settings-container .current-plan {
  width: 40%;
  display: inline-block;
}
.account-settings-container .current {
  display: inline-block;
  margin: 0 auto;
  vertical-align: top;
}
.account-settings-container .account-settings-button-container {
  display: block;
  justify-content: flex-end;
  align-items: center;
  clear: both;
  padding-bottom: 15px;
  text-align: right;
}
.account-settings-container .account-settings-button-container span {
  color: #646464;
  font-size: 12px;
}
.account-settings-container .plan-heading {
  font-weight: 600;
  color: #4f4f4f;
  margin: 21px 0 11px 5px;
}
.account-settings-container .current-plan-container {
  padding: 5px 0;
  display: flex;
}
.account-settings-container .current p {
  margin: 5px 0;
  text-align: center;
}
.account-settings-container .current .heading {
  color: #3d3d3d;
}
.account-settings-container .current img {
  vertical-align: middle;
  width: inherit;
  margin-right: 10px;
}
.account-settings-container .current .two-col {
  display: inline-block;
  vertical-align: top;
}
.account-settings-container .current .heading-bold {
  font-size: 15px;
  font-weight: 500;
  color: #3d3d3d;
  text-align: left;
}
.account-settings-container .current .detail {
  font-size: 12px;
  color: #666666;
}
.account-settings-container .current .detail .count {
  font-size: 15px;
  font-weight: 600;
  color: #ed7c02;
}
.account-settings-container .plan {
  width: 32%;
}
.account-settings-container .checks-count,
.account-settings-container .status-pages-count,
.account-settings-container .users-count {
  width: 21%;
  border-left: solid 0.5px #cacaca;
}
