.status-container {
  /* margin-top: 10px; */
  height: calc(100vh - 62px);
  overflow: auto;
}

.public-status-page {
  margin: 0 auto;
  width: 100%;
  max-width: 960px;
  height: 47px;
  border-radius: 4px;
  display: table;
}

.public-status-page .heading span {
  color: #89939c;
  font-weight: 400;
}

.public-status-page .heading {
  font-weight: 600;
  padding: 0 20px;
}

.more-container {
  height: 28px;
  width: 28px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}
.more-container:hover {
  background: #f2f2f2;
}
.more-container.is-opened .more-icon,
.more-container:hover .more-icon {
  opacity: 0.8;
}

.more-icon {
  position: relative;
  width: 4px;
  height: 4px;
  top: 5px;
  background-color: #222;
  border-radius: 50%;
  opacity: 0.3;
  display: block;
}

.more-icon:before,
.more-icon:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  background-color: inherit;
  border-radius: inherit;
}

.more-icon:before {
  top: 7px;
}

.more-icon:after {
  top: 14px;
}

.status-page-content {
  margin: 0 auto;
  height: 47px;
  width: 100%;
  max-width: 60rem;
  border-radius: 4px;
  display: table;
  margin-top: 10px;
  height: 41px;
  background-color: #ededed;
}

.status-page-content .app-name {
  font-weight: 500;
  color: #4a4a4a;
  line-height: 20px;
  text-transform: capitalize;
}

.app-url {
  color: #9f9f9f;
}

.status-page-content .settings-icon {
  float: right;
  margin-left: 10px;
  position: relative;
  bottom: 5px;
}

.statuspage-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 20px;
  margin-top: 5px;
}

.statuspage-default {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 2;
}

.statuspage-custom {
  width: 180px;
}

.statuspage-custom .label {
  color: #9f9f9f;
  line-height: 20px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.status-page-content .app-url span,
.statuspage-custom .domain {
  color: #218fff;
}

.statuspage-action {
  width: 10%;
  text-align: right;
  vertical-align: middle;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 12px;
}

.App .status-page-viewer {
  display: flex;
  padding: 0 10px;
  /* height: calc(100vh - 61px); */
}

.status-page-viewer .has-errored {
  box-shadow: 0 1px 0 0 #f65050;
  margin-bottom: 25px !important;
}

.status-page-viewer .helptext {
  position: relative;
  bottom: 25px;
  color: #f65050;
  font-size: 12px;
}

.App .status-container .status-page-viewer,
.App .status-container .viewer-controls,
.App .status-container .browser-window {
  box-sizing: border-box;
  height: calc(100vh - 75px);
  /* margin-bottom: 10px; */
}

.App .status-container .status-page-viewer {
  overflow: hidden;
  padding-top: 10px;
  /* height: calc(100vh - 61px); */
}

.viewer-controls {
  width: 420px;
  position: relative;
  background-color: #ffffff;
  padding: 12px;
  overflow: auto;
  height: calc(100vh - 61px);
}

.viewer-checks .checks-list {
  padding: 0;
  max-height: 310px;
  overflow: auto;
  margin-bottom: 0;
  margin-top: 0;
  border: 1px solid #e4e2e2;
}

.viewer-checks {
  background-color: #ffffff;
  /* border: solid 1px #e4e2e2; */
  margin-bottom: 20px;
}

.viewer-title {
  font-weight: 500;
  margin-bottom: 20px;
}

.viewer-checks .checks-header {
  /* background-color: #f4f4f4; */
  /* border: solid 1px #e4e2e2; */
  /* border-top: 0;
  border-left: 0;
  font-weight: 600; */
  color: rgba(34, 34, 34, 0.5);
  padding: 10px 8px;
}

.checks-grip {
  content: '....';
  width: 14px;
  height: 30px;
  display: inline-block;
  overflow: hidden;
  line-height: 5px;
  padding: 3px 4px;
  vertical-align: middle;
  margin-top: 3px;
  margin-right: 12px;
  margin-left: 3px;
  font-size: 12px;
  font-family: sans-serif;
  letter-spacing: 2px;
  color: #cccccc;
  text-shadow: 0 0 1px black;
}

.grip-span {
  margin-left: -4%;
}

span.checks-grip::after {
  content: '.. .. ..';
}

.viewer-checks .checks-content {
  border: 1px solid #f0f0f0;
  border-left: 0;
  background: #fff;
  border-right: 0;
  border-bottom: 0;
  padding: 7px 18px;
  color: #414141;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.viewer-checks .checks-content:hover .checks-grip {
  color: #777;
  text-shadow: 0 0 1px black;
}

.viewer-checks li:first-child {
  border-top: 0;
}

.viewer-checks li:last-child {
  border-bottom: 1px solid #f0f0f0;
}

.browser-window {
  text-align: left;
  margin: 0 60px;
  width: calc(100% - 420px);
  display: inline-block;
  border-radius: 5px;
}

.browser-window .top-bar {
  height: 30px;
  position: sticky;
  top: 0px;
  border-radius: 5px 5px 0 0;
  border-top: thin solid #eaeae9;
  border-bottom: thin solid #dfdfde;
  background: linear-gradient(#e7e7e6, #e2e2e1);
}

.browser-window .circle {
  height: 8px;
  width: 8px;
  display: inline-block;
  border-radius: 50%;
  background-color: white;
  margin-right: 5px;
}

.browser-window .circles {
  margin: 5px 11px;
}

.browser-window .content {
  margin: 0;
  width: 100%;
  display: inline-block;
  border-radius: 0 0 5px 5px;
  background-color: #f4f4f4;
  height: calc(100vh - 116px);
  overflow: auto;
  border: 1px solid #e2e2e2;
  border-top: 0;
  box-sizing: border-box;
}

@media only screen and (max-width: 1300px) {
  .browser-window .content {
    zoom: 0.6;
  }
}

.browser-window .dev-tools {
  width: 100%;
  min-height: 50%;
  margin: 0;
  padding: 0;
}

.browser-window .dev-tools .bar {
  margin-top: -4px;
  border-top: thin solid #e7e7e6;
  border-bottom: thin solid #e7e7e6;
  color: #e7e7e6;
}

.browser-window .dev-tools .bar .dev-bar-content {
  padding: 10px;
  float: left;
}

.browser-window .dev-tools .bar .close {
  float: right;
  border-left: thin solid #e7e7e6;
  padding: 10px;
}

.browser-window .dev-tools .content .html {
  height: 100%;
  width: 69%;
  border-right: thin solid #e7e7e6;
}

.browser-window .dev-tools .content .css {
  float: right;
  height: 100%;
  width: 30%;
}

.clear {
  clear: both;
}

.viewer-controls input,
.viewer-controls select,
.viewer-controls textarea {
  margin: 0px 0 30px 0;
  display: inline-block;
  box-sizing: border-box;
  height: 30px;
  background-color: #ffffff;
  box-shadow: 0 1px 0 0 #dadfe3;
  border: none;
  border-radius: 0;
  width: 100%;
  color: #222222;
  padding: 0;
}

.viewer-controls input[type='checkbox'] {
  margin: 0;
}

.viewer-controls label {
  font-weight: 500;
  font-size: 12px;
  color: rgba(34, 34, 34, 0.5);
  display: block;
}

.statuspage-form-action {
  text-align: right;
  padding: 15px 10px;
  position: absolute;
  width: 100%;
  z-index: 15;
  bottom: 0;
  background: #fff;
  border-top: 1px solid #e4e2e2;
}

.statuspage-form-action button.form-ok {
  border-radius: 3px;
  background-color: var(--bg-dark);
  color: #fff;
}

.statuspage-form-action button.form-ok:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.statuspage-form-action button.form-cancel {
  border-radius: 3px;
  background-color: #faf9f7;
  color: #646464;
}

.status-actions-dropdown .profile-dropdown__item:hover {
  background: #f9fbfd;
}

.status-actions-dropdown .profile-dropdown__item {
  border: 0;
  border-bottom: 1px #f2f2f2 solid;
  width: 100%;
}

.status-actions-dropdown {
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.05),
    0 1px 4px rgba(0, 0, 0, 0.15);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.05), 0 1px 4px rgba(0, 0, 0, 0.15);
  border: 0;
  padding: 0;
  min-width: 90px;
  top: -30px;
  left: auto;
  right: 25px;
  border-radius: 2px;
  border-top: 1px #ebeef0 solid;
  text-align: center;
}

.delete-confirm__message.statuspage_delete .delete-check-url {
  font-weight: 500;
  color: #218fff;
}

.status-actions {
  cursor: pointer;
  margin-top: -14px;
}

.status-actions-dropdown::after,
.status-actions-dropdown::before {
  bottom: 100%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
}

.status-actions-dropdown::after {
  border-color: rgba(211, 219, 218, 0);
  border-left-color: #f9f9f9;
  border-width: 5px;
  right: -11px;
  top: 40%;
}

.status-actions-dropdown:before {
  border-color: rgba(229, 229, 229, 0);
  border-left-color: #ebeef0;
  border-width: 6px;
  right: -13px;
  top: 40%;
}

.delete-confirm__message.statuspage_delete .name span {
  padding-left: 70px;
  word-break: break-all;
}

.statuspage-checkslist {
  max-width: 900px;
  margin: 30px auto;
}

.statuspage-checks {
  background-color: #ffffff;
  border-radius: 5px;
  margin: 0;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding-bottom: 3px;
  padding-top: 10px;
  padding-left: 12px;
  padding-right: 10px;
}

.statuspage-checks:first-child {
  padding-top: 15px;
}

.statuspage-checks:last-child {
  padding-bottom: 20px;
}

.statuspage-checks-content {
  display: flex;
  justify-content: space-around;
  padding-top: 1em;
  padding-right: 1em;
  padding-left: 1em;
}

.statuspage-checks-name-div {
  display: flex;
  justify-content: left;
  align-items: center;
  flex-grow: 1;
}

.statuspage-checks-name {
  font-weight: 400;
  color: #218fff;
  font-size: 0.9em;
  padding-left: 1em;
  width: 220px;
  cursor: pointer;
}

a {
  color: #4a93e3;
  text-decoration: none;
}

.statuspage-checks-title {
  font-size: 0.85em;
  font-weight: 400;
  width: 130px;
}

.statuspage-checks-data {
  font-size: 1.1em;
  font-weight: bold;
  color: #929e91;
  padding-top: 8px;
  width: 130px;
}

.statuspage-checks-downtime-percentage {
  font-size: 0.8em;
  font-weight: 600;
}

.statuspage-checks-downtime {
  width: 130px;
}

.statuspage-checks-downtime-data {
  width: 130px;
}

.content-placeholder {
  display: inline-block;
  background: #f6f7f8;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(8%, #eee),
    color-stop(18%, #ddd),
    color-stop(33%, #eee)
  );
  background: -webkit-linear-gradient(left, #eee 8%, #ddd 18%, #eee 33%);
  background: linear-gradient(to right, #eee 8%, #ddd 18%, #eee 33%);
  -webkit-background-size: 800px 104px;
  background-size: 800px 104px;
  height: inherit;
  position: relative;
}

.bar-chart-loading {
  margin: 10px 0 10px 10px;
  box-sizing: border-box;
  height: 20px;
  background: #2ab653;
  opacity: 0.6;
}
.App .status-container .statuspage-controls {
  position: relative;
}

.App .status-container .statuspage-controls .viewer-controls {
  height: calc(100vh - 148px);
  box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.12);
}

.statuspage-controls .viewer-title {
  margin: 0;
  line-height: 45px;
  padding-left: 20px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
}

.statuspage-controls .row-title {
  background-color: #ffffff;
  height: 45px;
  position: sticky;
  top: 0;
}
