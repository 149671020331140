.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
}

.animated.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.rrt-confirm-holder {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
}

.rrt-confirm-holder .shadow {
  width: 100%;
  height: 100%;
  /* background-color: rgba(50, 58, 68, 0.8) */
}

.rrt-confirm-holder .rrt-confirm {
  width: 320px;
  background-color: white;
  position: absolute;
  z-index: 9;
  top: 60px;
  left: 50%;
  margin-left: -160px;
  box-shadow: 3px 3px 20px #333;
  border-radius: 4px;
  overflow: hidden;
}

.rrt-confirm-holder .rrt-confirm .rrt-message {
  width: 100%;
  padding: 5%;
  min-height: 50px;
  font-size: 1em;
  background-color: white;
  text-align: center;
  clear: both;
}

.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  height: 50px;
  text-transform: capitalize;
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  float: left;
  border-top: 1px solid #f0f0f0;
  overflow: hidden;
  cursor: pointer;
}

.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button:focus {
  outline: none;
}

body.toastr-confirm-active {
  overflow: hidden;
}

.redux-toastr *,
.redux-toastr *:before,
.redux-toastr *:after {
  box-sizing: border-box;
}

.redux-toastr .top-left,
.redux-toastr .top-right,
.redux-toastr .top-center,
.redux-toastr .bottom-left,
.redux-toastr .bottom-right,
.redux-toastr .bottom-center {
  width: 350px;
  position: fixed;
  z-index: 99999999;
  padding: 0 10px;
}

.redux-toastr .top-left,
.redux-toastr .top-right,
.redux-toastr .top-center {
  top: 0;
}

.redux-toastr .top-right,
.redux-toastr .bottom-right {
  right: 0;
}

.redux-toastr .bottom-left,
.redux-toastr .bottom-right,
.redux-toastr .bottom-center {
  bottom: 0;
}

.redux-toastr .top-left,
.redux-toastr .bottom-left {
  left: 0;
}

.redux-toastr .top-center,
.redux-toastr .bottom-center {
  left: 50%;
  margin-left: -175px;
}

@media (max-width: 320px) {
  .redux-toastr .top-left,
  .redux-toastr .top-right,
  .redux-toastr .top-center,
  .redux-toastr .bottom-left,
  .redux-toastr .bottom-right,
  .redux-toastr .bottom-center {
    width: 320px;
  }

  .redux-toastr .top-center,
  .redux-toastr .bottom-center {
    margin-left: -160px;
  }
}

.redux-toastr .toastr {
  background-color: #fcfcfc;
  width: 100%;
  min-height: 70px;
  overflow: hidden;
  margin: 10px 0;
  border-radius: 4px;
  position: relative;
  z-index: 2;
  color: #333;
  opacity: 1;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.22);
}

.redux-toastr .toastr:hover:not(.rrt-message) {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.44);
  opacity: 1;
}

.redux-toastr .toastr .toastr-status {
  width: 100%;
  height: 5px;
}

.redux-toastr .toastr .toastr-status.success {
  background-color: #60bb71;
}

.redux-toastr .toastr .toastr-status.warning {
  background-color: #f7a336;
}

.redux-toastr .toastr .toastr-status.info {
  background-color: #58abc3;
}

.redux-toastr .toastr .toastr-status.error {
  background-color: #db6a64;
}

.redux-toastr .toastr .rrt-left-container,
.redux-toastr .toastr .rrt-right-container {
  float: left;
  text-align: center;
  overflow: hidden;
}

.redux-toastr .toastr .rrt-left-container {
  width: 80px;
  top: 0;
  left: 0;
  position: absolute;
  bottom: 0;
}

.redux-toastr .toastr .rrt-left-container .rrt-holder {
  width: 70px;
  height: 100%;
  position: absolute;
  top: 50%;
  line-height: 60px;
}

.redux-toastr .toastr .rrt-left-container .toastr-icon {
  fill: white;
  vertical-align: middle;
  margin-top: 5px;
  width: 25px !important;
  height: 25px !important;
}

.redux-toastr .toastr .rrt-middle-container {
  width: 65%;
  margin-left: 80px;
  position: relative;
  float: left;
  font-size: 16px;
  text-align: left;
  padding: 10px 5px 10px;
  display: flex;
  align-items: center;
  height: 100%;
  min-height: 70px;
}

.redux-toastr .toastr .rrt-middle-container .rrt-title {
  line-height: 18px;
  font-weight: 400;
}

.redux-toastr .toastr .rrt-right-container {
  width: 10%;
}

.redux-toastr .toastr .close-toastr {
  width: 10%;
  position: absolute;
  top: 7px;
  right: 0;
  background-color: transparent;
  font-size: 10px;
  font-weight: 600;
  border: none;
  outline: none;
  color: #727681;
  cursor: pointer;
  font-family: 'Helvetica Neue', Helvetica, Arial sans-serif;
}

.redux-toastr .toastr .close-toastr:hover {
  opacity: 1;
}

.redux-toastr .toastr .close-toastr:focus {
  outline: none;
}

.redux-toastr .toastr.rrt-info,
.redux-toastr .toastr.rrt-success,
.redux-toastr .toastr.rrt-error {
  color: white;
}

.redux-toastr .toastr.rrt-info {
  background-color: #58abc3;
}

.redux-toastr .toastr.rrt-success .rrt-left-container .rrt-holder {
  width: 100%;
  top: 66%;
}

.redux-toastr .toastr.rrt-success .rrt-left-container {
  background-color: #75c484;
}

.redux-toastr .toastr.rrt-success .rrt-title {
  padding-left: 20px;
  line-height: 1.38;
}

.redux-toastr .toastr.rrt-success,
.redux-toastr .toastr.rrt-warning {
  background-color: #fff;
  color: #222;
}

.redux-toastr .toastr.rrt-warning .rrt-holder {
  background-color: #ffad1f;
  margin-top: -35px;
}

.redux-toastr .toastr.rrt-success .rrt-holder {
  margin-top: -45px;
}

.redux-toastr .toastr.rrt-error .rrt-holder {
  margin-top: -35px;
}

.redux-toastr .toastr.rrt-warning .rrt-progressbar {
  background-color: #d87e09;
}

.redux-toastr .toastr.rrt-error {
  background-color: #db6a64;
}

.redux-toastr .toastr.rrt-error .rrt-progressbar {
  background-color: #c5352e;
}

.redux-toastr .toastr.rrt-light .rrt-progressbar {
  background-color: #ccc;
}

.redux-toastr .toastr.rrt-light .toastr-icon {
  fill: #333 !important;
}

.redux-toastr .toastr.rrt-message {
  opacity: 1;
  border: 1px solid #dbdbdb;
}

.redux-toastr .toastr.rrt-message .rrt-title {
  width: 90%;
  height: 50px;
  text-align: center;
  overflow: hidden;
  font-size: 1.2em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 50px;
  padding: 0 20px;
}

.redux-toastr .toastr.rrt-message .rrt-text {
  width: 100%;
  max-height: 400px;
  overflow: hidden;
  overflow-y: auto;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  background-color: white;
  padding: 15px;
  font-size: 1.1em;
  margin-bottom: 20px;
}

.redux-toastr .toastr.rrt-message .rrt-text img {
  display: block;
  margin: 10px auto;
  max-width: 100%;
}

.redux-toastr .toastr.rrt-message .close-toastr {
  height: 50px;
}

.redux-toastr .toastr .rrt-progress-container {
  height: 5px;
  margin: 0 -20px -20px -60px;
  position: absolute;
  bottom: 20px;
  width: 100%;
}

.redux-toastr .toastr .rrt-progress-container .rrt-progressbar {
  border-radius: 0 0 0 4px;
  height: 100%;
}

.redux-toastr .toastr-attention {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.rrt-confirm-holder .rrt-confirm {
  width: 620px;
  margin-left: -310px;
}

.remove-confirm-modal {
  padding: 10px 20px;
}

.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder {
  display: block;
  padding-top: 20px;
  width: 100%;
  position: relative;
  height: 70px;
  padding-right: 20px;
}

.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button.rrt-ok-btn {
  background-color: var(--bg-dark);
  border-color: var(--bg-dark);
  border-radius: 4px;
  color: #fff;
}

.rrt-confirm-holder
  .rrt-confirm
  .rrt-buttons-holder
  .rrt-button.rrt-cancel-btn {
  margin-right: 10px;
}

.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button {
  float: right;
  min-width: 70px;
  background-color: #f8f9fa;
  min-width: 70px;
  font-weight: 600;
  border-radius: 2px;
  border-radius: 2px;
  font-weight: 600;
  line-height: 28px;
  padding: 0 12px;
  position: relative;
  text-align: center;
  color: #585858;
  font-size: 12px;
  border: solid 1px #dadfe3;
  opacity: 1;
  cursor: pointer;
  height: auto;
  letter-spacing: 0.3px;
  font-weight: 700;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
}

.rrt-confirm-holder .rrt-confirm .rrt-message {
  font-size: 17px;
  font-weight: 600;
  text-align: left;
  padding: 14px 20px 4px;
  font-size: 16px;
  font-size: 16px;
  font-weight: 500;
  color: #4f4f4f;
  margin: 0;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
}

.rrt-confirm-holder .shadow {
  background: rgba(9, 30, 66, 0.54);
}

.rrt-confirm-holder .rrt-confirm {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.05), 0 1px 4px rgba(0, 0, 0, 0.15);
  border: 0;
}

.remove-confirm-modal {
  line-height: 22px;
}

.remove-confirm-modal b {
  font-weight: 600;
}

.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder {
  padding: 12px 24px 12px 0;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
  height: 56px;
  background: #f3f3f3;
  border-top: 1px solid #ebeef0;
  padding: 12px 24px 12px 0;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
}
